import { Checkbox, Form, Input, Radio, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./CompanyDetailOne.css";

import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";

import { createCompany } from "../../../../redux/actions/companyActions";
import {
  updateCompanyDetails,
  updateCompanyGallery,
} from "../../../../redux/reducers/companyReducer";

import { toast } from "react-toastify";
import PrivacyPolicy from "../../../../components/CommonComponents/PrivacyPolicy";
import TermAndCondition from "../../../../components/CommonComponents/TermAndcondition";
import UploadComponent from "../../../../components/CommonComponents/UploadComponent";
import { scrollToFirstError } from "../../../../utils/utils";

const CompanyDetailsTwo = ({ setCurrentStep, fileList, setFileList }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const companyDetails = useSelector((state) => state.company.details);

  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [isprivacyVisible, setisprivacyVisible] = useState(false);

  const showTermsModal = () => {
    setIsTermsModalVisible(true);
  };
  const showPrivacyModal = () => {
    setisprivacyVisible(true);
  };
  const closeTermsModal = () => {
    setIsTermsModalVisible(false);
    setisprivacyVisible(false);
  };

  const handleBackButtonClick = () => {
    setCurrentStep(1);
  };

  const handleFormChange = (_, allValues) => {
    dispatch(updateCompanyDetails(allValues));
  };

  useEffect(() => {
    form.setFieldsValue(companyDetails);
  }, [form, companyDetails]);

  const handleS3Urls = (s3Urls) => {
    const s3UrlsString = s3Urls.join(",");
    dispatch(updateCompanyGallery(s3UrlsString));
  };

  return (
    <>
      <section className="head_sec_pro_rect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <DashboardHeader
                title="Company Details"
                backButtonLink={handleBackButtonClick}
                style={{ marginTop: "80px" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="Company_details_sec_new sec_form_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="company_detials_wrapper">
                <Form
                  form={form}
                  onValuesChange={handleFormChange}
                  name="validationOnly"
                  layout="vertical"
                  autoComplete="off"
                  className="company_detail_form"
                >
                  <Form.Item
                    name="website"
                    label="Company Website"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid website URL",
                        pattern:
                          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Eg: https://www.test.com"
                      id="website"
                    />
                  </Form.Item>

                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Radio.Group id="location">
                      <Radio value={1} className="workmode_location">
                        On-site
                      </Radio>
                      <Radio value={2} className="workmode_location">
                        Remote
                      </Radio>
                      <Radio value={3} className="workmode_location">
                        Hybrid
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="no_of_employees"
                    label="Number of Employees"
                    rules={[
                      {
                        required: true,
                        message: "Please select number of employees!",
                      },
                    ]}
                  >
                    <Radio.Group id="no_of_employees">
                      <Radio.Button value={1} className="num0femp">
                        0 - 50
                      </Radio.Button>
                      <Radio.Button value={2} className="num0femp">
                        51 - 100
                      </Radio.Button>
                      <Radio.Button value={3} className="num0femp">
                        101 - 200
                      </Radio.Button>
                      <Radio.Button value={4} className="num0femp">
                        201-300
                      </Radio.Button>
                      <Radio.Button value={5} className="num0femp">
                        301-500
                      </Radio.Button>
                      <Radio.Button value={6} className="num0femp">
                        500+
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item name="file" label="Company Gallery">
                    <UploadComponent
                      fileList={fileList}
                      setFileList={setFileList}
                      onS3UrlsChange={handleS3Urls}
                    />
                  </Form.Item>

                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                              "Please agree to the terms and conditions!"
                            ),
                      },
                    ]}
                  >
                    <Checkbox className="term_condition" id="terms">
                      I Agree to{" "}
                      <Link onClick={showTermsModal}>Terms of Service,</Link>{" "}
                      <Link onClick={showPrivacyModal}>Privacy Policy, </Link>
                      and our default Notification Settings
                    </Checkbox>
                  </Form.Item>
                </Form>
              </div>
              <Form.Item>
                <div className="new_sub_cont">
                  <Space>
                    <SubmitButton form={form} />
                  </Space>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </section>
      <TermAndCondition
        isVisible={isTermsModalVisible}
        onClose={closeTermsModal}
      />
      <PrivacyPolicy isVisible={isprivacyVisible} onClose={closeTermsModal} />
    </>
  );
};

export default CompanyDetailsTwo;

const SubmitButton = ({ form }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.token);
  const company = useSelector((state) => state?.company?.details);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      dispatch(createCompany(token, company, navigate));
    } catch (error) {
      scrollToFirstError(error.errorFields);
      toast.error("Please fill in the company details correctly");
    }
  };

  return (
    <button className="saveContinue" onClick={handleSubmit}>
      Save & Continue
    </button>
    // <Button
    //   type="primary"
    //   htmlType="submit"
    //   className="submitbtn"

    // >
    //   Save and continue
    // </Button>\
  );
};
