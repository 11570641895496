import { Card, Image, Progress, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { websocketUrl } from "../../../../api/instance";
import { clock } from "../../../../assets/images/images";
import useInterviewWebSocket from "../../../../hooks/useInterviewWebSocket";
import "../InterviewModule/InterviewModule.css";
import InterviewModuleNav from "./InterviewModuleNav";

import ThreeDotLoader from "../../../../components/Loaders/ThreeDotLoader/ThreeDotLoader";
import { Analyse } from "./Analyse";
import { Congratulations } from "./Congratulations";

import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import HairoListening from "../../../../assets/gifs/HairoHeadNode_1.gif";
import HairoLipSync from "../../../../assets/gifs/HairoMute_1.gif";
import { ReactComponent as DisableMicIcon } from "../../../../assets/images/disable-mic-icon.svg";
import { ReactComponent as MicListening } from "../../../../assets/images/mike_listening.svg";
import useAudioRecorder from "../../../../hooks/useAudioRecorder";
import { useWindowFocus } from "../../../../hooks/useWindowFocus";
import {
  trackHaireIdleTime,
  trackTabSwitch,
} from "../../../../redux/actions/jobSeekerActions";
import { Malpractice } from "./Malpractice";
import { UnderReview } from "./UnderReview";
import WarningModal from "./WindowFocus";
import screenRecordingSessionStore from "../../../../zustand/screenRecordingSessionStore";
import screenRecordingStore from "../../../../zustand/screenRecordStore";
import { formatDuration } from "../../../../utils/utils";
import { toast } from "react-toastify";
import IdleWarningModal from "./popups/IdleWarningPopup";
import CustomPopup from "./popups/CustomPopup";
import ScreenSharePermissionPopup from "./ScreenSharePermissionPopup";
import ScreenSharePopup from "./popups/ScreenSharePopup";
import ResultsCountdown from "./ResultsCountdown";

const InterviewPanelReady = ({ interviewStatusDetails }) => {
  const location = useLocation();
  const data = location?.state?.data;
  const { interviewId, jobname, companyName } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const { startRecordingSession, stopRecordingSession, uploadChunk } =
    screenRecordingSessionStore();
  const {
    startScreenRecording,
    stopScreenRecording,
    showModal,
    isScreenSelectionInProgress,
  } = screenRecordingStore();
  const url = `${websocketUrl}/interview_audio_process/${interviewId}/`;
  const [loadingResults, setLoadingResults] = useState(false);
  const onResultsReceived = async () => {
    setLoadingResults(false);
    greetingHasBeenSent.current = false;
    stopRecordingSession(token, interviewId);
    stopScreenRecording();
    // try {
    //   await Promise.all([
    //     stopRecordingSession(token, interviewId),
    //     stopScreenRecording(),
    //   ]);
    //   console.log("Successfully stopped recording and screen capture.");
    // } catch (error) {
    //   console.error("Error stopping recording or screen capture:", error);
    // }
  };
  const [isEndModalVisible, setIsEndModalVisible] = useState(false);
  const [isSkipModalVisible, setIsSkipModalVisible] = useState(false);
  const initialCountDownDone = useRef(false);
  const [elapsedTime, setElapsedTime] = useState(0); // State for the timer value
  const timerRef = useRef(null);

  // Ref to keep track of the timeout to end the interview after inactivity
  const endInterviewTimeoutRef = useRef(null);

  // Function to calculate the current recording duration
  const getCurrentRecordingDuration = () => {
    const { recordingStartTime } = screenRecordingStore.getState();
    if (!recordingStartTime) return 0;
    const currentDuration = Math.round(
      (Date.now() - recordingStartTime) / 1000
    ); // Get elapsed seconds
    return currentDuration;
  };

  // Format the duration as HH:MM:SS
  const formattedDuration = formatDuration(getCurrentRecordingDuration());

  // AUDIO
  const {
    combinedMessage,
    socketLoading,
    audioPath,
    setAudioPath,
    sendData,
    interviewResult,
    autoEnd,
    interviewPercentage,
    greetingHasBeenSent,
    setSocketLoading,
  } = useInterviewWebSocket(
    url,
    onResultsReceived,
    interviewStatusDetails,
    initialCountDownDone
  );
  const {
    startRecording,
    stopRecording,
    isRecording,
    isSpeaking,
    audioBlob,
    mediaRecorder,
  } = useAudioRecorder();
  const [hasSentData, setHasSentData] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAudioButtonDisabled, setIsAudioButtonDisabled] = useState(true);

  const [messageText, setMessageText] = useState("Analyzing your answer");
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //TIMER
  const [timer, setTimer] = useState(180); // Timer set for 2 minutes
  const [timerActive, setTimerActive] = useState(false);

  // TAB SWITCH
  const { isWindowFocused, setIsWindowFocused } = useWindowFocus();
  const [tabSwitchWarningVisible, setTabSwitchWarningVisible] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);

  const [interviewUnderReview, setInterviewUnderReview] = useState(false);
  const [isEndInterviewClicked, setIsEndInterviewClicked] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(true);

  const decodedJobTitle = decodeURIComponent(jobname);
  const decodedCompanyName = decodeURIComponent(companyName);

  const navData = {
    jobname: decodedJobTitle,
    CompanyName: decodedCompanyName,
  };

  // Tabswitch code start
  // useEffect(() => {
  //   if (!interviewUnderReview && showPopUp && !showModal && !isScreenSelectionInProgress) {
  //     if (!isWindowFocused) {
  //       const newCount = tabSwitchCount + 1;
  //       setTabSwitchCount(newCount);

  //       if (newCount >= 1 && newCount < 4) {
  //         dispatch(trackTabSwitch(interviewId, token));
  //         setTabSwitchWarningVisible(true);
  //       } else if (newCount >= 4) {
  //         handleEndInterview();
  //         setInterviewUnderReview(true);
  //       }
  //     }
  //   }
  // }, [isWindowFocused, interviewUnderReview]);
  // Tabswitch code end

  const [idleTime, setIdleTime] = useState(0);
  const [isIdleModalVisible, setIsIdleModalVisible] = useState(false);
  const [idleWarningCount, setIdleWarningCount] = useState(0);
  const idleTimerRef = useRef(null);
  const modalTimerRef = useRef(null);

  useEffect(() => {
    if (
      !isRecording &&
      combinedMessage.current &&
      combinedMessage.current !== "" &&
      combinedMessage.current !== "undefined"
    ) {
      setMessageText("Analyzing your answer");

      resetIdleTimer();

      // setIdleWarningCount(0);
    }
  }, [isRecording, combinedMessage.current]);

  // Stop idle time tracking when the user starts answering
  useEffect(() => {
    if (isRecording) {
      if (idleTimerRef.current) clearInterval(idleTimerRef.current); // Stop idle timer
      if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    }
  }, [isRecording]);

  const resetIdleTimer = () => {
    setIdleTime(0);
    if (idleTimerRef.current) clearInterval(idleTimerRef.current);
    idleTimerRef.current = setInterval(() => {
      setIdleTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const handleUserActivity = () => {
    if (!isIdleModalVisible && !isRecording) {
      resetIdleTimer();
    }
  };

  // Add event listeners for detecting user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (idleTimerRef.current) clearInterval(idleTimerRef.current);
      if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    };
  }, [isIdleModalVisible]);

  useEffect(() => {
    if (
      idleTime >= 30 &&
      idleWarningCount < 3 &&
      !isRecording &&
      combinedMessage.current
    ) {
      setIsIdleModalVisible(true);
      setIdleWarningCount((prevCount) => prevCount + 1);
      clearInterval(idleTimerRef.current);

      modalTimerRef.current = setTimeout(() => {
        if (isIdleModalVisible) {
          setIsIdleModalVisible(false);
          if (idleWarningCount === 3) {
            handleEndInterview();
          } else {
            resetIdleTimer();
          }
        }
      }, 15000);
    }
  }, [idleTime]);

  const handleContinueInterview = () => {
    setIsIdleModalVisible(false);
    if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    if (idleWarningCount === 3) {
      handleEndInterview();
    } else {
      resetIdleTimer();
    }
  };

  const handleCancelWarning = () => {
    setTabSwitchWarningVisible(false);
  };

  useEffect(() => {
    if (isAudioPlaying || socketLoading) {
      setIsAudioButtonDisabled(false);
    }
  }, [isAudioPlaying, socketLoading]);

  useEffect(() => {
    // Send data when the listening has stopped and there's transcript available
    if (!isRecording && audioBlob && !hasSentData) {
      sendData({
        data: {
          interview_stage: "processing",
          ai_question: combinedMessage.current,
          time_to_start_answer: elapsedTime,
          question_asked_at: formattedDuration,
        },
        candidateAudio: audioBlob,
      });
      setHasSentData(true); // Prevent multiple sends
    }
  }, [isRecording, audioBlob, combinedMessage.current, sendData]);

  useEffect(() => {
    // This will update whenever 'transcript' changes and listening has stopped.
    if (!isRecording && audioBlob) {
      setMessageText("Analyzing your answer");
    }
  }, [isRecording, audioBlob]);

  // VIDEO
  const videoRef = useRef(null);
  const stream = useRef(null);

  const startVideo = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      }
      stream.current = mediaStream;
    } catch (err) {
      console.error("Error accessing microphone and camera", err);
    }
  };

  const stopVideo = () => {
    if (stream.current) {
      stream.current.getTracks().forEach((track) => track.stop());
      stream.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      stopRecording();
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const requestMediaPermissions = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      }
      stream.current = mediaStream;

      await startRecordingSession(token, interviewId);
    } catch (err) {
      console.error("Error accessing microphone and camera", err);
    }
  };

  const enableScreenShare = async () => {
    try {
      await startScreenRecording(
        token,
        interviewId,
        uploadChunk,
        // onPermissionGranted callback
        () => {
          setIsModalVisible(false);
        },
        // showToast callback
        (message) => {
          toast.error(message, {
            position: "top-right",
            duration: 4000,
            // You can customize the toast style as needed
          });
        }
      );
    } catch (error) {
      // Additional error handling if needed
      console.error("Screen sharing failed:", error);

      // Optional: Show a more generic error toast if the specific toast wasn't shown
      toast.error("Failed to start screen sharing. Please try again.", {
        position: "top-right",
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    let newAudio = null;

    const playNewAudio = async () => {
      if (audioPath && !isEndInterviewClicked) {
        setIsAudioPlaying(true);

        newAudio = new Audio(
          `${process.env.REACT_APP_BACKEND_APP_URL}${audioPath}`
        );

        try {
          newAudio.muted = true;
          await newAudio.play();

          // Unmute after a short delay
          setTimeout(() => {
            newAudio.muted = false;
          }, 100);
        } catch (error) {
          // Handle play() promise rejection (usually due to the browser's autoplay policy)
          setIsAudioPlaying(false);
          console.error("Failed to play audio:", error);
        }

        newAudio.onended = () => {
          if (autoEnd) {
            handleEndInterview();
          } else {
            setIsAudioPlaying(false);
            setElapsedTime(0); // Reset elapsed time when audio ends
            timerRef.current = setInterval(() => {
              setElapsedTime((prevTime) => prevTime + 1); // Increment the elapsed time every second
            }, 1000);
          }
        };
      }
    };

    playNewAudio();

    // Cleanup previous audio instance when audioPath changes or component unmounts
    return () => {
      if (newAudio) {
        newAudio.pause();
        newAudio.onended = null;
      }
      clearInterval(timerRef.current); // Cleanup the timer
    };
  }, [audioPath]);

  const [popupCompontStatus, setPopupCompontStatus] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupCompontStatus((prevStatus) => {
        if (prevStatus < 4) {
          return prevStatus + 1;
        } else {
          clearInterval(intervalId);
          return 4;
        }
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, [popupCompontStatus]);

  const handleAutomaticProgression = () => {
    setShowPopUp(true);
    setshowCont(true);
    sethideCont(false);
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <Link onClick={getReady}>Get ready</Link>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const endRenderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <Link onClick={getEnd}>Thank you</Link>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
        {/* <p>Thank you!</p> */}
      </div>
    );
  };

  const [showCont, setshowCont] = useState(false);
  const [hideCont, sethideCont] = useState(true);

  const getReady = () => {
    setshowCont(true);
    sethideCont(false);
  };

  const getEnd = () => {
    sethideCont(false);
  };

  const handleEndInterview = () => {
    if (idleWarningCount === 3) {
      dispatch(trackHaireIdleTime(interviewId, token));
    }
    sendData({
      data: {
        interview_stage: "end",
        ai_question: "",
        question_asked_at: formattedDuration,
      },
    });
    sethideCont(true);
    setIsEndInterviewClicked(true);
    setLoadingResults(true);
    setAudioPath("");
    setIsAudioPlaying(false);
    stopVideo();

    // Clear the inactivity timeout when ending the interview
    if (endInterviewTimeoutRef.current) {
      clearTimeout(endInterviewTimeoutRef.current);
      endInterviewTimeoutRef.current = null;
    }
  };

  const showEndModal = () => {
    // to handle browser back click
    window.history.pushState({ customBack: true }, "");
    window.addEventListener("popstate", () => showEndModal());
    setIsEndModalVisible(true);
    setShowPopUp(false);
  };

  const handleEndCancel = () => {
    setIsEndModalVisible(false);
    setIsWindowFocused(true);
  };

  const handleConfirmEndInterview = () => {
    handleEndInterview();
    setIsEndModalVisible(false);
  };

  const showSkipModal = () => {
    if (!isAudioPlaying && !socketLoading) {
      setIsSkipModalVisible(true);
    }
  };

  const handleSkipCancel = () => {
    setIsSkipModalVisible(false);
    setIsWindowFocused(true);
  };

  const handleSkipQuestion = () => {
    stopRecording();
    setTimer(0);
    sendData({
      data: {
        interview_stage: "processing",
        ai_question: combinedMessage.current,
        candidate_answer: "[Candidate skipped this question ask next question]",
        question_asked_at: formattedDuration,
      },
    });
    setIsSkipModalVisible(false);
  };

  const handleStartRecording = () => {
    startRecording();
    setTimer(180); // Reset timer to 3 minutes
    setTimerActive(true); // Start the time
    setHasSentData(false);

    clearInterval(timerRef.current);

    if (endInterviewTimeoutRef.current) {
      clearTimeout(endInterviewTimeoutRef.current);
      endInterviewTimeoutRef.current = null;
    }
  };

  const handleStopRecording = () => {
    const delayInMilliseconds = 500;
    setTimeout(() => {
      stopRecording();
      setTimerActive(false);

      // Start the inactivity timer after the user stops recording
      // endInterviewTimeoutRef.current = setTimeout(() => {
      //   handleEndInterview(); // End the interview if the user does not respond within 2 minutes
      // }, 120000); // 120000 milliseconds = 2 minutes
    }, delayInMilliseconds);
    const timer = setTimeout(() => {
      setMessageText("Generating next question");
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      handleStopRecording();
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  // This effect runs when a new question is asked to reset the timer
  useEffect(() => {
    if (!isRecording && audioPath) {
      setTimer(180); // Reset timer to 2 minutes
      setTimerActive(false); // Ensure timer is not running
    }
  }, [audioPath]);

  // Format timer to display as MM:SS
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const renderInterviewResult = (selectionStatus) => {
    let content;

    switch (selectionStatus) {
      case "Shortlisted":
        content = <Congratulations />;
        break;
      case "Under-Review":
        content = <UnderReview />;
        break;
      default:
        content = <UnderReview />;
    }

    return <div className="result-message">{content}</div>;
  };

  useEffect(() => {
    if (!isModalVisible) {
      initialCountDownDone.current = false;
      requestMediaPermissions();
      const timeoutId = setTimeout(() => {
        handleAutomaticProgression();
        startVideo();
      }, 3000);

      const handlePopState = (event) => {
        showEndModal();
      };
      // Push initial state
      window.history.pushState({ customBack: true }, "");
      // to handle browser back click
      window.addEventListener("popstate", handlePopState);

      return () => {
        clearTimeout(timeoutId);
        stopVideo();
        initialCountDownDone.current = false;
        if (endInterviewTimeoutRef.current) {
          clearTimeout(endInterviewTimeoutRef.current);
        }
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [isModalVisible]);

  return isModalVisible ? (
    <ScreenSharePopup
      navData={navData}
      isModalVisible={isModalVisible}
      enableScreenShare={enableScreenShare}
    />
  ) : (
    <>
      {interviewUnderReview ? (
        <>
          <InterviewModuleNav data={navData} />
          {loadingResults ? (
            hideCont ? (
              <ResultsCountdown
                renderTime={renderTime}
                onComplete={() => {
                  getEnd();
                  return {
                    shouldRepeat: false,
                    delay: 1,
                  };
                }}
              />
            ) : (
              <Analyse />
            )
          ) : interviewResult ? (
            <Malpractice />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <InterviewModuleNav data={navData} />
          {loadingResults ? (
            hideCont ? (
              <ResultsCountdown
                renderTime={endRenderTime}
                onComplete={() => ({
                  shouldRepeat: false,
                  delay: 1,
                })}
              />
            ) : (
              <Analyse />
            )
          ) : interviewResult ? (
            renderInterviewResult(interviewResult.selection_status)
          ) : (
            <>
              {hideCont && (
                <ResultsCountdown
                  renderTime={renderTime}
                  onUpdate={(remainingTime) => {
                    if (remainingTime == 1) {
                      setMessageText("");
                      setSocketLoading(true);
                      initialCountDownDone.current = true;
                    }
                  }}
                  onComplete={() => ({
                    shouldRepeat: false,
                    delay: 1,
                  })}
                />
              )}
              {showCont && (
                <div id="int_panel_main">
                  <section className="int_panel_body_sec">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="int_panel_body_sec_inner">
                            <div className="int_panel_body_sec_inner_top">
                              <div className="progress_section">
                                <Progress
                                  percent={interviewPercentage}
                                  format={(percent) => `${percent}% Completed`}
                                  style={{
                                    width: "100%",
                                    height: "20px",
                                  }}
                                />
                              </div>
                              <div className="timer">
                                <img src={clock} alt="" />
                                {timerActive ? formatTime() : "03:00"}
                              </div>
                            </div>
                            <Image
                              preview={false}
                              src={
                                isAudioPlaying ? HairoLipSync : HairoListening
                              }
                              style={{
                                position: "relative",
                                bottom: "50px",
                                height: "66vh",
                                aspectRatio: 16 / 9,
                              }}
                            />

                            <div
                              className={
                                !socketLoading &&
                                (combinedMessage.current == null ||
                                  combinedMessage.current === "" ||
                                  combinedMessage.current === "undefined")
                                  ? "welcm_text_inner2"
                                  : "welcm_text_inner"
                              }
                            >
                              <h6 style={{ userSelect: "none" }}>
                                {!socketLoading ? (
                                  combinedMessage.current !== null &&
                                  combinedMessage.current !== "" &&
                                  combinedMessage.current !== "undefined" ? (
                                    combinedMessage.current
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      userSelect: "none",
                                    }}
                                  >
                                    <ThreeDotLoader />
                                    <p
                                      className="generating_p"
                                      style={{
                                        userSelect: "none",
                                      }}
                                    >
                                      {messageText}
                                    </p>
                                  </div>
                                )}
                              </h6>
                            </div>
                            <Card className="your_videoi_part_sec">
                              <h6>You</h6>
                              <video
                                ref={videoRef}
                                style={{ width: "100%", borderRadius: "10px" }}
                                autoPlay
                                muted
                                playsInline
                              ></video>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="int_panel_body_allow_btn_sec">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="int_panel_body_allow_btn_sec_inner">
                            <div
                              className={`skipQ  ${
                                isAudioPlaying || socketLoading || isRecording
                                  ? "slipQ_disable"
                                  : ""
                              }`}
                              onClick={showSkipModal}
                            >
                              <span>Skip Question</span>
                            </div>
                            <div className="record_vdeo_icn_sec">
                              <button
                                className={`tap_speak_button ${
                                  isAudioPlaying ||
                                  socketLoading ||
                                  isAudioButtonDisabled
                                    ? "slipQ_disable2"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (isRecording) {
                                    handleStopRecording();
                                  } else {
                                    handleStartRecording();
                                  }
                                }}
                                disabled={
                                  isAudioPlaying ||
                                  socketLoading ||
                                  isAudioButtonDisabled
                                }
                              >
                                {isRecording ? (
                                  <Tooltip
                                    className="tooltip_interview"
                                    color="#464646"
                                    title={
                                      "Tap this button to end your response and submit it."
                                    }
                                    placement="right"
                                  >
                                    <div className="tap_to_stop">
                                      <div
                                        style={{
                                          background:
                                            "linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%)",
                                          height: 46,
                                          width: 46,
                                          borderRadius: 34,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Bars
                                          height="24"
                                          width="24"
                                          color={"#fff"}
                                          ariaLabel="bars-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          visible={true}
                                        />
                                      </div>
                                      {isRecording
                                        ? "Tap to stop"
                                        : "Tap to answer"}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    className="tooltip_interview"
                                    color="#464646"
                                    title={
                                      "Tap this button to talk and allow our AI to begin registering your response."
                                    }
                                    placement="right"
                                  >
                                    {isAudioPlaying ||
                                    socketLoading ||
                                    isAudioButtonDisabled ? (
                                      <DisableMicIcon />
                                    ) : (
                                      <MicListening />
                                    )}
                                    {isRecording
                                      ? "Tap to stop"
                                      : "Tap to answer"}
                                  </Tooltip>
                                )}
                              </button>
                            </div>
                            <div className="ent_int_btn">
                              <button onClick={showEndModal}>
                                End Interview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
              <CustomPopup
                isModalVisible={isEndModalVisible}
                handleCancel={handleEndCancel}
                handleConfirm={handleConfirmEndInterview}
                question={"Are you sure you want to end the interview?"}
                subtitle={
                  "Ending the interview may impact your job application."
                }
              />
              <CustomPopup
                isModalVisible={isSkipModalVisible}
                handleCancel={handleSkipCancel}
                handleConfirm={handleSkipQuestion}
                question={"Are you sure you want to skip the question?"}
                subtitle={
                  "Skipping the question may impact your job application."
                }
              />
              <IdleWarningModal
                isVisible={isIdleModalVisible}
                onContinue={handleContinueInterview}
                onEndInterview={handleEndInterview}
                warningCount={idleWarningCount}
                key={idleWarningCount} // Use the warning count as a key to restart the timer
              />
              <ScreenSharePermissionPopup />
            </>
          )}
          {!interviewResult && (
            <WarningModal
              tabSwitchWarningVisible={tabSwitchWarningVisible}
              handleCancelWarning={handleCancelWarning}
              tabSwitchCount={tabSwitchCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(InterviewPanelReady);
