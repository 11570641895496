import React from "react";
import "./commonComponant.css";
import { PageNotFoundImg } from "../../assets/images/images";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/actions/authActions";
const PageNotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = (event) => {
    event.preventDefault();
    try {
      // Check if we can go back in history
      if (window.history.length <= 2) {
        // If no history, sign out and redirect to home
        dispatch(signOut());
      } else {
        // Normal back navigation
        navigate(-1);
      }
    } catch (error) {
      console.error("Navigation error:", error);
      // Fallback to home page if navigation fails
      navigate("/", { replace: true });
    }
  };

  const redirectToHome = (event) => {
    event.preventDefault();
    // navigate("/", { replace: true });
    dispatch(signOut());
  };

  return (
    <section className="container">
      <div className="page_not_found_main">
        <img src={PageNotFoundImg} alt="page not found" />
        <h3>Page Not Found</h3>
        <p>
          We couldn’t find the page you are looking for.
          <br />
          Please You can go back to the{" "}
          <a href="#" onClick={redirectToHome}>
            Previous Page
          </a>{" "}
          or go back to the{" "}
          <a href="#" onClick={redirectToHome}>
            Home Page
          </a>
        </p>
        <button onClick={redirectToHome} className="Pagenotfoundbackbtn">
          Go Back
        </button>
      </div>
    </section>
  );
};

export default PageNotFound;
