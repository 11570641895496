import React from 'react'
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";
import {  backSlash,
  logo_sld_1,
  logo_sld_2,
  logo_sld_5,} from "../../../assets/images/images";
import "animate.css/animate.min.css";
import "animate.css/animate.min.css";
import OwlCarousel from "react-owl-carousel";

const NewRecrutierTestimonials = () => {
    
    const optionsNew = {
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        responsive: {
          0: { items: 1 },
          600: { items: 2 },
          1000: { items: 3 },
        },
      };
      
      const testimonials = [
        {
          text: "Haire.ai made our hiring process much easier at Arcitech. The AI helped us quickly find the best candidates, saving us a lot of time. It's been a great tool for our team.",
          name: "Arcitech",
          // position: "HR Manager",
          imgSrc: logo_sld_2,
        },
        {
          text: "At Coincade, Haire.ai made our hiring process smooth and efficient. The AI did a great job of finding the right candidates quickly, which really helped us fill positions faster.",
          name: "Coincade",
          // position: "HR Manager",
          imgSrc: logo_sld_5,
        },
        {
          text: "Haire.ai has really improved our hiring at Hirect. The AI interviews and assessments made it easy to find the right candidates quickly. It’s been a big help for our team.",
          name: "Hirect",
          // position: "HR Manager",
          imgSrc: logo_sld_1,
        },
      ];
      
      const Testimonial = ({ text, name, position, imgSrc }) => (
        <div className="client_testi_sec_single">
          <img src={backSlash} className="back_slash_sec" />
          <p>{text}</p>
          <div className="client_testi_img">
            <div className="client_testi_img_img">
              <img src={imgSrc} />
            </div>
            <div className="client_testi_img_text">
              <h6>{name}</h6>
            </div>
          </div>
        </div>
      );
  return (
    <>
      
      <section className="client_testi_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="client_testi_sec_inner">
                  <div
                    className="client_testi_sec_head wow animate__zoomIn"
                    data-wow-duration="4s"
                  >
                    <h6>
                       <span>Recruiter Testimonials </span>
                    </h6>
                  </div>
                  <OwlCarousel
                    className="owl-theme client_testi_sec_inner_body"
                    {...optionsNew}
                  >
                    {testimonials.map((testimonial, index) => (
                      <Testimonial key={index} {...testimonial} />
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
      </section>

      
    </>
  )
}

export default NewRecrutierTestimonials
