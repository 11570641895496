import "./LoginPopup.css";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import loginCodeStore from "../../zustand/loginCodeStore";
import ReusableModal from "./ReusablModal";
import EmailLoginPopup from "./EmailLoginPopup";
import OtpVerificationModal from "../../pages/SignIn/OtpVerificationModal";

const LoginPopup = () => {
  const [proceedWithCode, setproceedWithCode] = useState(false);
  const { loading, initialModalOpen, modalOpen } = loginCodeStore();

  const [timer, setTimer] = useState();

  const intervalRef = useRef(null); // Add this to track the interval

  const startTimer = () => {
    // Clear any existing interval first
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set the new interval
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  // Clean up the interval when component unmounts
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const {
    formState: { errors },
  } = useForm();
  return (
    <ReusableModal
      modalOpens={initialModalOpen}
      loading={loading}
      isHeaderVisible={!modalOpen}
    >
      {!proceedWithCode ? (
        <>
          <p className="login_title">To Apply for this Job</p>
          <GoogleInUp
            className="signup_google_sec"
            forContent="Log in with Google"
          />
          <div className="DividerContainer">
            <hr />
            <span>or</span>
            <hr />
          </div>

          <button
            type="button"
            className="forgot-passoword"
            onClick={
              () => setproceedWithCode(true)
              //  navigate("/login-with-verification-code")
            }
          >
            Login with Verification Code
          </button>
        </>
      ) : (
        <>
          <OtpVerificationModal
            timer={timer}
            setTimer={setTimer}
            startTimer={startTimer}
          />
          <EmailLoginPopup
            timer={timer}
            setTimer={setTimer}
            startTimer={startTimer}
          />
        </>
      )}
    </ReusableModal>
  );
};

export default LoginPopup;
