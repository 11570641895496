import { useNavigate } from "react-router-dom";
import { haire_gif_new, new_bg } from "../../assets/images/images";
import { ReactComponent as RecuriterIconBlue } from "../../assets/images/blue_bag.svg";
import { ReactComponent as JobSeekerIconBlue } from "../../assets/images/blue_glass.svg";
import { ReactComponent as JobSeekerIcon } from "../../assets/images/jobseeker-icon.svg";
import { ReactComponent as RecuriterIcon } from "../../assets/images/recruiter-icon.svg";
import { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Typography } from "@mui/material";
import userStore from "../../zustand/userStore";
import { useDispatch, useSelector } from "react-redux";

const UserRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth?.user);
  const { updateRole, loading } = userStore();
  const [selectedUserType, setSelectedUserType] = useState("");

  const handleUserTypeSelection = (userType) => {
    setSelectedUserType(userType);
  };

  const handleProceed = async () => {
    await updateRole(
      token,
      user,
      selectedUserType === "recruiter" ? 1 : 2,
      dispatch,
      navigate
    );
  };

  return (
    <>
      <section className="login_sec_new">
        <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>
              Experience Smart Recruitment in AI-Driven Recruitment Excellence.
            </p>
          </div>
          <div className="singin_sec_new_text">
            <div className="a">
              <h4 className="signin_qoutes">
                Step into the future of hiring with our AI tool that not only
                matches candidates to ideal positions but also initiates smart
                interviews.
              </h4>
            </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper wrapper">
          <div></div>
          <div>
            <div className="logo">
              <img
                className="haire-logo"
                src={haire_gif_new}
                onClick={() => navigate("/", { replace: true })}
                alt="Logo"
              />
            </div>

            <br />
            <Typography mb={1} className="disclaimer">
              Are you looking to hire or searching for your next opportunity?
            </Typography>

            <p className="subtitle">Choose your role to continue</p>
            <br />
            <div className="toggle-buttons">
              <div
                className={`toggle-btn ${
                  selectedUserType === "recruiter" ? "active" : ""
                }`}
                onClick={() => handleUserTypeSelection("recruiter")}
              >
                {/* <img src={recruiter_icon} alt="" /> */}
                {selectedUserType === "recruiter" ? (
                  <RecuriterIconBlue className="icon-s" />
                ) : (
                  <RecuriterIcon className="icon-s" />
                )}
                Recruiter
              </div>
              <div
                className={`toggle-btn ${
                  selectedUserType === "jobseeker" ? "active" : ""
                }`}
                onClick={() => handleUserTypeSelection("jobseeker")}
              >
                {/* <img src={jobseeker_icon} alt="" /> */}
                {selectedUserType === "jobseeker" ? (
                  <JobSeekerIconBlue className="icon-s" />
                ) : (
                  <JobSeekerIcon className="icon-s" />
                )}
                Job Seeker
              </div>
            </div>
            <br />
            <div className="login_btn_blue">
              <button onClick={handleProceed} className="register-comon-btn">
                {loading ? (
                  <ThreeCircles
                    visible={true}
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </section>
    </>
  );
};

export default UserRole;
