import React from 'react'
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";
import { Helmet } from 'react-helmet';
import { logo_sld_11,
  logo_sld_12,
  logo_sld_13,
  logo_sld_14,
  logo_sld_15,
  logo_sld_16,} from "../../../assets/images/images";
  import OwlCarousel from "react-owl-carousel";
  
const NewTrustPartner = () => {

    const options = {
        lazyLoad: true,
        loop: true,
        margin: 50,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: false,
        // autoHeight: true,
        // rewindNav: true,
        mouseDrag: true,
        touchDrag: true,
        smartSpeed: 1000,
        nav: false,
        dots: true,
        responsive: {
          0: {
            items: 2,
          },
    
          600: {
            items: 4,
          },
    
          1024: {
            items: 6,
          },
    
          1366: {
            items: 6,
          },
        },
      };


  return (
    <>
      <section className="trusted_part_sec_new wow animate__fadeInRight new_land_trust" data-wow-duration="3s">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="">
                <div className="trusted_partne_logo_slide">
                  <h6>Trusted By</h6>
                  <div className="trusted_logo_slide_owl">
                    <OwlCarousel className="olw_slide_logo" {...options}>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_11} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_12} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_13} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_14} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_15} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="slide_logo_img_sec">
                          <img src={logo_sld_16} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewTrustPartner
