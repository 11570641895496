import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { haire_logo } from "../../assets/images/images";
import { calculateTimeSpan, formatDateTimeToLocalWithOffset } from "../utils";
import { addWatermarkToAllPages } from "./addWaterMark";

export const assesmentPdf = async (selectedAssessment, selectedApplicant) => {
  const content = selectedAssessment.map((item, index) => ({
    problemSolvingText: item?.data?.problem_solving?.description,
    problemSolvingRating: item?.data?.problem_solving?.rating,
    behavioralSkillsText: item?.data?.behavioral_skills?.description,
    behavioralSkillsRating: item?.data?.behavioral_skills?.rating,
    communicationSkillsText: item?.data?.communication_skills?.description,
    communicationSkillsRating: item?.data?.communication_skills?.rating,
    technicalSkillsText: item?.data?.technical_skills?.description,
    technicalSkillsRating: item?.data?.technical_skills?.rating,
    overallRatingText: item?.data?.overall_impression,
    overallRating: item?.data?.overall_rating,
  }));

  const pdf = new jsPDF();
  const container = document.createElement("div");
  container.style.width = "800px";
  container.style.margin = "0 auto";
  container.style.padding = "0";

  // Append container to document body for html2canvas usage
  document.body.appendChild(container);

  let yOffset = 10; // Initial y offset
  let isFirstPage = true;

  const interviewDuration = calculateTimeSpan(
    selectedApplicant?.interview_details?.started_at,
    selectedApplicant?.interview_details?.ended_at
  );
  const timestamp =
    selectedApplicant?.interview_details?.date &&
    selectedApplicant?.interview_details?.started_at
      ? formatDateTimeToLocalWithOffset(
          selectedApplicant?.interview_details?.date,
          selectedApplicant?.interview_details?.started_at
        )
      : "Timestamp unknown";

  for (const data of content) {
    const element = document.createElement("div");
    element.style.backgroundColor = "#fff";
    element.style.fontFamily = "Arial, sans-serif";
    element.style.fontSize = "14px";
    element.style.lineHeight = "1.6";
    element.style.color = "#333";
    element.style.padding = "0px";
    element.style.pageBreakInside = "avoid";
    element.style.marginBottom = "10px"; // Reduced margin-bottom for spacing

    if (isFirstPage) {
      element.innerHTML = `
        <div style="display: flex;">
          <img src="${haire_logo}" style="width: 80px; height: 40px; margin-right: 20px;">
          <div style="text-align: right; flex-grow: 1;">
            <div style="font-family: helvetica; font-weight: bold; font-size: 16px;">${selectedApplicant?.user_full_name} | ${selectedApplicant.job_title}</div>
            <div style="font-family: helvetica; font-weight: normal; font-size: 14px;">${timestamp}</div>
          </div>
        </div>
        <div>
          <div style="padding: 0;">
            <div style="font-size: 12px; font-weight: bold; color: #333; margin-bottom: 0;">Assessment Report</div>
            <div style="font-size: 18px; color: #000; font-weight: bold; margin-bottom: 0;">${selectedApplicant.job_title}</div>
            <hr style="margin-top: 10px; border: 0; border-top: 5px solid #1865C1;" />
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; margin: 0 0 8px 0;">Problem Solving: <span style="color: #1865C1;">Rating: ${data.problemSolvingRating}/10</span></h2>
            <p>${data.problemSolvingText}</p>
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; margin: 0 0 5px 0;">Behavioral Skills: <span style="color: #1865C1;">Rating: ${data.behavioralSkillsRating}/10</span></h2>
            <p>${data.behavioralSkillsText}</p>
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; margin: 0 0 5px 0;">Communication Skills: <span style="color: #1865C1;">Rating: ${data.communicationSkillsRating}/10</span></h2>
            <p>${data.communicationSkillsText}</p>
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; margin: 0 0 5px 0;">Technical Skills: <span style="color: #1865C1;">Rating: ${data.technicalSkillsRating}/10</span></h2>
            <p>${data.technicalSkillsText}</p>
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; margin: 0 0 5px 0;">Overall Rating: <span style="color: #1865C1;">Rating: ${data.overallRating}/10</span></h2>
            <p>${data.overallRatingText}</p>
          </div>
        </div>
      `;
      isFirstPage = false;
    } else {
      element.innerHTML = `
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; margin: 0 0 8px 0;">Problem Solving: <span style="color: #1865C1;">Rating: ${data.problemSolvingRating}/10</span></h2>
          <p>${data.problemSolvingText}</p>
        </div>
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; margin: 0 0 8px 0;">Behavioral Skills: <span style="color: #1865C1;">Rating: ${data.behavioralSkillsRating}/10</span></h2>
          <p>${data.behavioralSkillsText}</p>
        </div>
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; margin: 0 0 8px 0;">Communication Skills: <span style="color: #1865C1;">Rating: ${data.communicationSkillsRating}/10</span></h2>
          <p>${data.communicationSkillsText}</p>
        </div>
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; margin: 0 0 8px 0;">Technical Skills: <span style="color: #1865C1;">Rating: ${data.technicalSkillsRating}/10</span></h2>
          <p>${data.technicalSkillsText}</p>
        </div>
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; margin: 0 0 8px 0;">Overall Rating: <span style="color: #1865C1;">Rating: ${data.overallRating}/10</span></h2>
          <p>${data.overallRatingText}</p>
        </div>
      `;
    }

    container.appendChild(element);
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const imgHeight = (canvas.height * 190) / canvas.width;

    if (yOffset + imgHeight > pdf.internal.pageSize.getHeight() - 20) {
      // Consider space for footer
      pdf.addPage();
      yOffset = 10; // Reset y-offset for the new page
    }

    pdf.addImage(imgData, "PNG", 10, yOffset, 190, imgHeight);
    yOffset += imgHeight + 10; // Increment y-offset for the next content, add consistent space
  }

  const addFooter = () => {
    const pageCount = pdf.internal.getNumberOfPages(); // Get total number of pages
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.setTextColor(150);
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.text(
        "Page " + i + " of " + pageCount,
        pdf.internal.pageSize.getWidth() - 20,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }
  };
  addWatermarkToAllPages(pdf);
  addFooter(); // Add page numbers after all content has been added
  pdf.save(
    `${selectedApplicant?.user_full_name}-${selectedApplicant.job_title}-Assessment.pdf`
  );
  document.body.removeChild(container); // Clean up
};
