import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import {
  fetchInterviewDetails,
  fetchTryInterviewDetails,
} from "../api/applicantAPI";
import ApplicantDashboard from "../components/ApplicantComponents/ApplicationDashboard";
import MaintainancePage from "../components/CommonComponents/MaintainancePage";
import PageNotFound from "../components/CommonComponents/PageNotFound";
import Aboutus from "../pages/AboutUs/Aboutus";
import Checkout from "../pages/Checkout/Checkout";
import Contactus from "../pages/Contactus/Contactus";
import Applicantprofile from "../pages/Dashboard/ApplicantDashboard/Applicantprofile/Applicantprofile";
import MyCompany from "../pages/Dashboard/ApplicantDashboard/CompanyProfile/CompanyProfile";
import ConfirmInformation from "../pages/Dashboard/ApplicantDashboard/ConfirmInformation/ConfirmInformation";
import UpdateApplicantResume from "../pages/Dashboard/ApplicantDashboard/ConfirmInformation/UpdateApplicantResume";
import { BetterLuck } from "../pages/Dashboard/ApplicantDashboard/InterviewModule/BetterLuck";
import { Congratulations } from "../pages/Dashboard/ApplicantDashboard/InterviewModule/Congratulations";
import InterviewAnalyser from "../pages/Dashboard/ApplicantDashboard/InterviewModule/InterviewAnalyser";
import InterviewModule from "../pages/Dashboard/ApplicantDashboard/InterviewModule/InterviewModule";
import InterviewModuleNav from "../pages/Dashboard/ApplicantDashboard/InterviewModule/InterviewModuleNav";
import InterviewPanelReady from "../pages/Dashboard/ApplicantDashboard/InterviewModule/InterviewPanelReady";
import Interviewpanel from "../pages/Dashboard/ApplicantDashboard/InterviewModule/Interviewpanel";
import InteviewReshedule from "../pages/Dashboard/ApplicantDashboard/InterviewModule/InteviewReshedule";
import { UnderReview } from "../pages/Dashboard/ApplicantDashboard/InterviewModule/UnderReview";
import RelatedJobs from "../pages/Dashboard/ApplicantDashboard/RelatedJobs/RelatedJobs";
import ResumeBuilder from "../pages/Dashboard/ApplicantDashboard/ResumeBuilder/ResumeBuilder";
import ResumeEnhancement from "../pages/Dashboard/ApplicantDashboard/ResumeBuilder/ResumeEnhancement";
import ResumeCustomize from "../pages/Dashboard/ApplicantDashboard/ResumeCustomize/ResumeCustomize";
import ResumeGenerator from "../pages/Dashboard/ApplicantDashboard/ResumeGenerator/ResumeGenerator";
import ResumePicker from "../pages/Dashboard/ApplicantDashboard/ResumePicker/ResumePicker";
import CompanyDetails from "../pages/Dashboard/RecruiterDashboard/CompanyDetails/CompanyDetails";
import Dashboard from "../pages/Dashboard/RecruiterDashboard/Dashboard";
import JobDetails from "../pages/Dashboard/RecruiterDashboard/JobDetails/JobDetails";
import PaymentFailed from "../pages/Dashboard/RecruiterDashboard/Monitization/Pages/PaymentFailed/PaymentFailed";
import PaymentSucceded from "../pages/Dashboard/RecruiterDashboard/Monitization/Pages/PaymentFailed/paymentSucceded";
import ViewInvitedJobs from "../pages/Dashboard/RecruiterDashboard/MyJobs/ViewInvitedJobs";
import ViewJobsData from "../pages/Dashboard/RecruiterDashboard/MyJobs/ViewJobsData";
import ViewRequestedJobs from "../pages/Dashboard/RecruiterDashboard/MyJobs/ViewRequestedJobs";
import Features from "../pages/Features/Features";
import Home from "../pages/Home/Home";
import FortgetPassword from "../pages/Login/FortgetPassword";
import Login from "../pages/Login/Login";
import LoginEmailPass from "../pages/Login/LoginEmailPass";
import LoginVerificationCode from "../pages/Login/LoginVerificationCode";
import ResetPassword from "../pages/Login/ResetPassword";
import Pricing from "../pages/Pricing/Princing";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy/RefundPolicy";
import AccountVerification from "../pages/SignIn/AccountVerification";
import RecruitingJobSeeking from "../pages/SignIn/RecruitingJobSeeking";
import SignUpPage from "../pages/SignIn/SignUpPage";
import TermsAndConditon from "../pages/TermsCondition/TermAndCondition";
import TryHaire from "../pages/TryHaire/TryHaire";
import TryHaireAssesment from "../pages/TryHaire/TryHaireAssesment";
import TryHaireModals from "../pages/TryHaire/TryHaireModal";
import TryInterview from "../pages/TryHaire/TryInterview";
import CheckAuthAndRedirectJobs from "../utils/CheckAuthAndRedirect";
import CheckRoute from "./CheckRoute";
import PrivateRoute from "./PrivateRoute";
import ViewDemoJob from "../pages/Dashboard/RecruiterDashboard/MyJobs/ViewDemoJob";
import LoginV2 from "../pages/Login/LoginV2";
import Results from "../pages/Dashboard/RecruiterDashboard/Results/Results";
import UserRole from "../pages/Login/UserRole";
import HomeLanding from "../pages/Home/HomeLanding";

// Wrapper component to handle authentication and redirection
const ProtectedJobRoute = () => {
  const authToken = useSelector((state) => state.auth.token);
  const location = useLocation();

  if (!authToken) {
    // Save the attempted URL to redirect back after login
    return <Navigate
      to="/login"
      state={{
        redirectTo: location.pathname,
        from: location.pathname
      }}
      replace
    />;
  }

  return <ViewJobsData />;
};

export const CommonRoutes = () => (
  <Routes>
    <Route path="/" element={<CheckRoute component={Home} />} />
    {/* <Route path="/sign-up" element={<CheckRoute component={SignUpPage} />} /> */}
    <Route path="/sign-up" element={<Navigate to="/login"  replace={true}/>} />
    <Route path="/login" element={<CheckRoute component={LoginV2} />} />
    <Route path="/user-role" element={<UserRole />} />
    <Route path="/ai-interview" element={<CheckRoute component={HomeLanding} />} />
    {/* <Route path="/user-role" element={<RecruitingJobSeeking />} /> */}
    <Route
      path="/login-with-phone"
      element={<CheckRoute component={LoginEmailPass} />}
    />
    <Route
      path="/login-with-verification-code"
      element={<CheckRoute component={LoginVerificationCode} />}
    />
    {/* Keep the login route public */}
    <Route path="/about-us" element={<CheckRoute component={Aboutus} />} />
    <Route path="/contact-us" element={<CheckRoute component={Contactus} />} />
    <Route path="/features" element={<CheckRoute component={Features} />} />
    <Route path="/pricing" element={<CheckRoute component={Pricing} />} />
    <Route
      path="/Term&Condition"
      element={<CheckRoute component={TermsAndConditon} />}
    />
    <Route
      path="/PrivacyPolicy"
      element={<CheckRoute component={PrivacyPolicy} />}
    />
    <Route
      path="/RefundPolicy"
      element={<CheckRoute component={RefundPolicy} />}
    />
    <Route path="/forget-password" element={<FortgetPassword />} />
    {/* Keep the forget-password route public */}
    <Route path="/reset-password" element={<ResetPassword />} />
    {/* Keep the reset-password route public */}
    <Route path="/jobs" element={<ApplicantDashboard />} />
    <Route path="/try-haire" element={<TryHaire />} />

    {/* <Route path="/try-haire/interview" element={<TryHaireInterview />} /> */}
    <Route path="/jobs/related-jobs/:jobId" element={<RelatedJobs />} />
    {/* <Route path="/resume-builder" element={<ResumeBuilder />} /> */}
    <Route path="/jobs/:jobId" element={<CheckAuthAndRedirectJobs />} />
    <Route path="/pagenotfound" element={<PageNotFound />} />
    <Route path="/maintainance-page" element={<MaintainancePage />} />
    <Route path="/:companyName/:companyId" element={<MyCompany />} />
    <Route
      path="/dashboard/my-jobs/myJobs/:jobId/view-jobs-data"
      element={<ProtectedJobRoute />}
    />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export const RecruiterRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/dashboard" />} />
    <Route path="/sign-up" element={<Navigate to="/dashboard" />} />
    <Route path="/login" element={<Navigate to="/dashboard" />} />
    <Route path="/login-with-phone" element={<Navigate to="/dashboard" />} />
    <Route path="/:companyName/:companyId" element={<MyCompany />} />
    <Route path="/user-role" element={<Navigate to="/dashboard" replace />} />
    <Route
      path="/try-haire/interview/:interviewId/:jobProfile/:company"
      element={
        <PrivateRoute
          element={<RedirectTryHaire />}
          excludedRoutes={[
            "/try-haire/interview/:interviewId/:jobProfile/:company",
          ]}
        />
      }
    />
    <Route
      path="/try-haire/interview/:interviewId/get-ready/:jobProfile/:company"
      element={
        <PrivateRoute
          element={<TryHaireModals />}
          excludedRoutes={[
            "/try-haire/interview/:interviewId/get-ready/:jobProfile/:company",
          ]}
        />
      }
    />
    <Route
      path="/try-haire/interview/:interviewId/assessment-and-transcript"
      element={
        <PrivateRoute
          element={<TryHaireAssesment />}
          excludedRoutes={[
            "/try-haire/interview/:interviewId/assessment-and-transcript",
          ]}
        />
      }
    />
    <Route
      path="/dashboard/*"
      element={<PrivateRoute element={<Dashboard />} />}
    />
    <Route
      path="/dashboard/job-details"
      element={<PrivateRoute element={<JobDetails />} />}
    />
    <Route
      path="/view-jobs-data"
      element={<PrivateRoute element={<ViewJobsData />} />}
    />
    <Route
      path="/view-jobs-data/results/:activeTab/:id"
      element={<PrivateRoute element={<Results />} />}
    />
    <Route
      path="/view-demo-jobs-data"
      element={<PrivateRoute element={<ViewDemoJob />} />}
    />
    <Route
      path="/confirm-information"
      element={<PrivateRoute element={<ConfirmInformation />} />}
    />
    <Route
      path="/account-verification"
      element={<PrivateRoute element={<AccountVerification />} />}
    />
    <Route
      path="/company-details"
      element={<PrivateRoute element={<CompanyDetails />} />}
    />
    <Route
      path="/interview-analyser"
      element={<PrivateRoute element={<InterviewAnalyser />} />}
    />
    <Route
      path="/inteview-reshedule"
      element={<PrivateRoute element={<InteviewReshedule />} />}
    />
    <Route
      path="/check-out"
      element={<PrivateRoute element={<Checkout />} />}
    />
    <Route
      path="/congratulations"
      element={<PrivateRoute element={<Congratulations />} />}
    />
    <Route
      path="/better-luck"
      element={<PrivateRoute element={<BetterLuck />} />}
    />
    <Route
      path="/payment-succeded"
      element={<PrivateRoute element={<PaymentSucceded />} />}
    />
    <Route
      path="/payment-failed"
      element={<PrivateRoute element={<PaymentFailed />} />}
    />
    <Route
      path="/view-inivited-jobs-data"
      element={<PrivateRoute element={<ViewInvitedJobs />} />}
    />
    <Route
      path="/view-requested-jobs-data"
      element={<PrivateRoute element={<ViewRequestedJobs />} />}
    />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export const ApplicantRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/applicant-dashboard" />} />
    <Route path="/login" element={<Navigate to="/applicant-dashboard" />} />
    {JobsPageConditionalRedirect()}
    <Route
      path="/login-with-phone"
      element={<Navigate to="/applicant-dashboard" />}
    />
    <Route path="/resume-builder" element={<ResumeBuilder />} />
    <Route path="/jobs/:jobId" element={<CheckAuthAndRedirectJobs />} />
    {/* <Route path="/user-role" element={<Navigate to="/applicant-dashboard" replace />} /> */}
    {/* <Route path="/jobs/related-jobs/:jobId" element={<RelatedJobs />} /> */}
    <Route
      path="/resume-generator"
      element={<PrivateRoute element={<ResumeGenerator />} />}
    />
    <Route
      path="/resume-picker"
      element={<PrivateRoute element={<ResumePicker />} />}
    />
    <Route
      path="/resume-customize"
      element={<PrivateRoute element={<ResumeCustomize />} />}
    />
    <Route
      path="/view-jobs-data"
      element={<PrivateRoute element={<ViewJobsData />} />}
    />
    <Route
      path="/applicantprofile"
      element={<PrivateRoute element={<Applicantprofile />} />}
    />
    {/* Assuming you want this public */}
    <Route
      path="/applicant-dashboard"
      element={<PrivateRoute element={<ApplicantDashboard />} />}
    />
    <Route
      path="/confirm-information"
      element={<PrivateRoute element={<ConfirmInformation />} />}
    />
    <Route
      path="/resume-enhancement"
      element={<PrivateRoute element={<ResumeEnhancement />} />}
    />
    <Route
      path="/update-applicant-resume"
      element={<PrivateRoute element={<UpdateApplicantResume />} />}
    />
    <Route
      path="/account-verification"
      element={<PrivateRoute element={<AccountVerification />} />}
    />
    <Route
      path="/interview-analyser"
      element={<PrivateRoute element={<InterviewAnalyser />} />}
    />
    <Route
      path="/inteview-reshedule"
      element={<PrivateRoute element={<InteviewReshedule />} />}
    />
    <Route
      path="/check-out"
      element={<PrivateRoute element={<Checkout />} />}
    />
    <Route
      path="/congratulations"
      element={<PrivateRoute element={<Congratulations />} />}
    />
    <Route
      path="/better-luck"
      element={<PrivateRoute element={<BetterLuck />} />}
    />
    <Route
      path="/applicant-dashboard/related-jobs/:jobId"
      element={<PrivateRoute element={<RelatedJobs />} />}
    />
    <Route
      path="/applicant-dashboard/related-jobs/:jobId/interview-module/:interviewId"
      element={<PrivateRoute element={<InterviewModule />} />}
    />
    {/* <Route
      path="/applicant-dashboard/related-jobs/:jobId/start-interview/:interviewId"
      element={<PrivateRoute element={<StartInterview />} />}
    /> */}
    <Route
      path="/applicant-dashboard/related-jobs/:jobId/start-interview/:interviewId/:jobname/:companyName"
      element={<PrivateRoute element={<Interviewpanel />} />}
    />
    <Route
      path="/applicant-dashboard/related-jobs/:jobId/start-interview/:interviewId/interview-panel/interview-panel-ready/:jobname/:companyName"
      element={<PrivateRoute element={<RedirectInterviewPanelReady />} />}
    />
    <Route path="/:companyName/:companyId" element={<MyCompany />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

function JobsPageConditionalRedirect() {
  const redirectUrl = useSelector((state) => state.auth?.redirectUrl);

  if (redirectUrl) {
    return <Route path="/sign-up" element={<Navigate to={redirectUrl} />} />;
  }

  return (
    <Route path="/sign-up" element={<Navigate to="/applicant-dashboard" />} />
  );
}

const RedirectInterviewPanelReady = () => {
  const { interviewId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const [interviewDetails, setInterviewDetails] = useState(null);

  useEffect(() => {
    (async function () {
      try {
        const data = await fetchInterviewDetails(token, interviewId);
        if (data?.status === 200) {
          setInterviewDetails(data);
        }
      } catch (error) {
        console.log(error);
        setInterviewDetails({ signal: false });
      }
    })();
  }, [interviewId]);

  return (
    <>
      {interviewDetails?.signal === true && (
        <InterviewPanelReady interviewStatusDetails={interviewDetails} />
      )}
      {interviewDetails?.signal === false && (
        <>
          <InterviewModuleNav />
          <UnderReview />
        </>
      )}
    </>
  );
};

const RedirectTryHaire = () => {
  const { interviewId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const [interviewDetails, setInterviewDetails] = useState(null);

  useEffect(() => {
    (async function () {
      try {
        const data = await fetchTryInterviewDetails(token, interviewId);
        if (data?.status === 200) {
          setInterviewDetails(data);
        }
      } catch (error) {
        console.log(error);
        setInterviewDetails({ signal: false });
      }
    })();
  }, [interviewId]);

  return (
    <>
      {interviewDetails?.signal === true && (
        <TryInterview interviewStatusDetails={interviewDetails} />
      )}
      {interviewDetails?.signal === false && (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
};
