import { Tabs } from "antd";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Profile from "./profile";
import Assesment from "./assessment";
import Transcript from "./transcript";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import { useEffect } from "react";

const Results = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  // Create a custom event to signal tab/route changes
  useEffect(() => {
    // Dispatch custom event when location changes
    window.dispatchEvent(new CustomEvent("routeChanged"));

    // Dispatch custom event when component unmounts
    return () => {
      window.dispatchEvent(new CustomEvent("routeChanged"));
    };
  }, [location.pathname]);

  const handleTabChange = (key) => {
    // Dispatch custom event before navigation
    window.dispatchEvent(new CustomEvent("routeChanged"));
    navigate(key);
  };

  const tabItems = [
    {
      key: `/view-jobs-data/results/Profile/${id}`,
      label: "Profile",
      children: <Profile />,
    },
    {
      key: `/view-jobs-data/results/Transcript/${id}`,
      label: "Transcript",
      children: <Transcript />,
    },
    {
      key: `/view-jobs-data/results/Assessment/${id}`,
      label: "Assessment",
      children: <Assesment />,
    },
  ];

  const handleBackButtonClick = () => {
    // Dispatch custom event before going back
    window.dispatchEvent(new CustomEvent("routeChanged"));
    navigate(-1);
  };

  return (
    <>
      <Navbar />

      <div className="container-fluid results-container">
        <DashboardHeader
          title=""
          backButtonLink={handleBackButtonClick}
          backButtonText="Back"
        />
        <Tabs
          activeKey={location.pathname}
          onChange={handleTabChange}
          items={tabItems}
        />
        <Routes>
          <Route path="Profile/:id" element={<Profile />} />
          <Route path="Transcript/:id" element={<Transcript />} />
          <Route path="Assessment/:id" element={<Assesment />} />
        </Routes>
      </div>
    </>
  );
};

export default Results;
