import { useEffect, useState } from "react";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import CompanyDetailOne from "./CompanyDetailOne";
import CompanyDetailsTwo from "./CompanyDetailsTwo";
import { resetCompany } from "../../../../redux/reducers/companyReducer";
import { useDispatch } from "react-redux";

function CompanyDetails() {
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [editedContent, setEditedContent] = useState("");


  useEffect(() => {
    const scrollToTop = () => {
      if ('scrollBehavior' in document.documentElement.style) {
        // Modern browsers with smooth scroll support
        window.scrollTo({
          top: 0,
          behavior: 'auto' // or 'auto' for immediate jump
        });
      } else {
        // Fallback for older browsers
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };
  
    scrollToTop();
  }, [currentStep]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = ""; // This will trigger a confirmation dialog on most browsers
  };

  useEffect(() => {
    const handlePopState = (event) => {
      const confirmLeave = window.confirm(
        "Are you sure you want to leave? Your changes may not be saved."
      );
      if (confirmLeave) {
        dispatch(resetCompany()); // Dispatch the resetCompany action
        setTimeout(() => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        }, 1000); // Delay the removal of the event listener by 1 second
      } else {
        event.preventDefault(); // Prevent the navigation if they do not confirm
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      <Navbar />
      {currentStep === 1 ? (
        <CompanyDetailOne setCurrentStep={setCurrentStep} editedContent={editedContent} setEditedContent={setEditedContent} />
      ) : (
        <CompanyDetailsTwo setCurrentStep={setCurrentStep} fileList={fileList} setFileList={setFileList} />
      )}
    </>
  );
}

export default CompanyDetails;
