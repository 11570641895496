import { Skeleton, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { eye_icon } from "../../../../assets/images/images";
import NoJobApplicantFound from "../../../../components/CommonComponents/NoJobApplicantFound";
import { fetchJobsList } from "../../../../redux/actions/jobActions";
import { formatDate } from "../../../../utils/utils";
import { EmploymentFilter, Reset, Searchbar, SortFilter } from "./MyJobs";
import "./MyJobs.css";
import { setJobPageFilters } from "../../../../redux/reducers/jobReducer";
import { ThreeCircles } from "react-loader-spinner";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import TableSpan from "../../../../components/CommonComponents/TableSpan";
import { useSearchParams } from "react-router-dom";

const ClosedJobs = ({ activeTab, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const closedJobList = useSelector((state) => state.job.closedJobsList);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.job.loading);
  const closedJobsCount = useSelector((state) => state.job.closedJobsCount);
  const jobPageFilters = useSelector((state) => state.job.jobPageFilters);

  // Sync currentPage with URL (searchParams)
  useEffect(() => {
    const pageFromURL = searchParams.get("page") ?? 1;
    if (currentPage !== Number(pageFromURL)) {
      setCurrentPage(Number(pageFromURL));
    }
  }, [searchParams]);

  // Initial load: Set filters from jobPageFilters
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedType(jobPageFilters.type || "All");
      setSortCriteria(jobPageFilters.sortBy);
    }
  }, [jobPageFilters]);

  // API call when filters change: searchInput, selectedType, sortCriteria, currentPage
  useEffect(() => {
    dispatch(
      fetchJobsList(
        token,
        searchInput,
        selectedType === "All" ? undefined : selectedType,
        sortCriteria,
        status,
        currentPage
      )
    );
    dispatch(
      setJobPageFilters({
        current: currentPage,
        type: selectedType,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  }, [
    dispatch,
    token,
    searchInput,
    selectedType,
    sortCriteria,
    status,
    currentPage,
  ]);

  const handleViewJob = (jobId) => {
    const page = searchParams.get("page") ?? 1;
    navigate(
      `/dashboard/my-jobs/${activeTab}/${jobId}/view-jobs-data?page=${page}`
    );
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);
  };

  const handlePageChange = (page) => {
    setSearchParams({ page });
    setCurrentPage(page);
  };

  const handleSortChange = (criteria) => {
    setCurrentPage(1);
    setSortCriteria(criteria);
  };

  const handleTypeChange = (type) => {
    setCurrentPage(1);
    setSelectedType(type);
  };

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria(null);
    setSelectedType("All");
    setCurrentPage(1);
    dispatch(fetchJobsList(token, "", null, null, status, 1));
  };

  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div className="job-title-cell" data-th="Job Title" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Created date",
      dataIndex: "created_at",
      key: "created_at",
      render: (dateString) => (
        <div data-th="Created date">{formatDate(dateString)}</div>
      ),
    },
    {
      title: "Closed date",
      dataIndex: "closed_date",
      key: "closed_date",
      render: (dateString) => (
        <div data-th="Closed date">{formatDate(dateString)}</div>
      ),
    },
    {
      title: "Job Type",
      dataIndex: "employment_type",
      key: "employment_type",
      render: (employmentType) => {
        const types = Object.values(employmentType).join(", ");
        return <div data-th="Job Type">{types || "Not Specified"}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text) => <div data-th="Location">{text}</div>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <div data-th="Status">
          <TableSpan status={status} />
        </div>
      ),
    },
    {
      title: "View Details",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <a onClick={() => handleViewJob(record.id)}>
          <img src={eye_icon} alt="eye_icon" className="eye_icon" />
          View
        </a>
      ),
    },
  ];

  if (!closedJobList) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="row">
        <div className="filter-container">
          <div className="filter-section ">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
            <EmploymentFilter
              onTypeChange={handleTypeChange}
              selectedType={selectedType}
            />
            <Reset onReset={handleReset} />
          </div>
          <div className="sort-section">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div>
        </div>
        {/* Responsive layout */}
        <div className="filter-container-responsive">
          <div className="search-bar-section">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
          </div>
          <div className="responsive_filter">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">
                      Employement Type:
                    </span>
                    <EmploymentFilter
                      onTypeChange={handleTypeChange}
                      selectedType={selectedType}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Sort By:</span>
                    <SortFilter
                      onSortChange={handleSortChange}
                      selectedValue={sortCriteria}
                      activeTab={activeTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="filter-options-section-responsive">
            <EmploymentFilter
              onTypeChange={handleTypeChange}
              selectedType={selectedType}
            />
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div> */}
          <div>
            <Reset onReset={handleReset} />
          </div>
        </div>
        {/* Responsive layout */}
        {loading ? (
          <SkeletonLoader includeTable={true} excludeDetails={true} />
        ) : closedJobList && closedJobList.length > 0 ? (
          <Table
            columns={columns}
            dataSource={closedJobList}
            pagination={{
              pageSize: 12,
              total: closedJobsCount,
              current: currentPage,
              onChange: handlePageChange,
              showSizeChanger: false,
            }}
          />
        ) : (
          <NoJobApplicantFound />
        )}
      </div>
    </>
  );
};

export default ClosedJobs;
