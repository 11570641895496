import { Slider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { thick_right_icn, tick } from "../../../../../assets/images/images";
import {
  businessFeatures,
  enterpriseFeatures,
  freeFeatures,
} from "../../../../../utils/constants";
import { generateSliderMarks, getKeyByValue } from "../../../../../utils/utils";
import customPlanStore from "../../../../../zustand/customPlanStore";
import { UpgradePlan } from "./UpgradePlan";
import { ThreeCircles } from "react-loader-spinner";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Plans = ({
  free,
  business,
  enterprise,
  country,
  subscriptionPlan,
  handleSubscribePlan,
}) => {
  const {
    loading,
    formData,
    formErrors,
    setFormData,
    validateForm,
    validateField,
    submitCustomPlan,
  } = customPlanStore();
  const [selectedTab, setSelectedTab] = useState("free");
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData(name, value); // Update the state in the store\
    validateField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      await submitCustomPlan();
      await setHasSubmitted(true);
    }
  };

  const resetFormAndErrors = () => {
    setEnterpriseSliderValue(0); // Reset the slider to show the plans again
    setHasSubmitted(false); // Reset submission state if necessary
    customPlanStore.setState({
      formErrors: {},
      formData: { fullName: "", email: "", phone: "" },
    });
  };

  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };
  const enterpriseMarks = useMemo(
    () => ({
      ...generateSliderMarks(enterprise),
      100: "Custom", // Custom option at value 100
    }),
    [enterprise, country]
  );

  const [enterpriseSliderValue, setEnterpriseSliderValue] = useState(
    getKeyByValue(
      enterpriseMarks,
      subscriptionPlan?.credits_grant?.toString()
    ) ?? 0
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const shouldShowContent = enterpriseSliderValue === 100;

  const [isBusinessLoading, setIsBusinessLoading] = useState(false);
  const [isEnterpriseLoading, setIsEnterpriseLoading] = useState(false);

  const businessMarks = useMemo(
    () => generateSliderMarks(business),
    [business, country]
  );

  const [businessSliderValue, setBusinessSliderValue] = useState(
    getKeyByValue(businessMarks, subscriptionPlan?.credits_grant?.toString()) ??
      0
  );

  const getBusinessPlan = () => {
    if (business.length > 0) {
      const index = Math.round(
        (businessSliderValue / 100) * (business.length - 1)
      );
      return business[index] || business[0];
    }
    return null;
  };

  const getEnterprisePlan = () => {
    if (enterprise.length > 0 && enterpriseSliderValue < 100) {
      const index = Math.round(
        (enterpriseSliderValue / 100) * (enterprise.length - 1)
      );
      return enterprise[index] || enterprise[0];
    }
    return null;
  };

  useEffect(() => {
    // Reset the form data when the component is mounted
    customPlanStore.setState({
      formData: { fullName: "", email: "", phone: "" },
      formErrors: {},
    });

    return () => {
      // Optionally, clear the form data when the component is unmounted
      customPlanStore.setState({
        formData: { fullName: "", email: "", phone: "" },
        formErrors: {},
      });
    };
  }, []);

  return (
    <div className="pricing-container">
      <div class="pricing-button-container">
        <button
          className={`pricing-btn-free ${
            selectedTab === "free" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("free")}
        >
          Free
        </button>
        <button
          className={`pricing-btn-business ${
            selectedTab === "business" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("business")}
        >
          Business
        </button>
        <button
          className={`pricing-btn-enterprise ${
            selectedTab === "enterprise" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("enterprise")}
        >
          Enterprise
        </button>
      </div>

      {(selectedTab === "free" || window.innerWidth >= 768) && (
        <div className="pricing-card">
          <div className="min-h">
            <div className="pricing-header">Free</div>
            <div className="pricing-amount">{free[0]?.currency}0</div>
            {free?.map((plan) => (
              <div key={plan.id}>
                <div className="pricing-description min-hb">
                  {plan?.credits_grant} Free Credits - {plan?.currency}
                  {plan?.price}
                </div>
                <div>
                  <div className="pricing-description">
                    Features in free plan :
                  </div>
                  <div className="pricing-features">
                    <ul>
                      {freeFeatures.map((feature, index) => (
                        <li key={index}>
                          <img src={tick} alt="" className="tick" /> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {handleSubscribePlan ? (
            <button className="pricing-button2" onClick={() => {}}>
              Explore!
            </button>
          ) : (
            <button className="pricing-button" onClick={handleClick}>
              {"Request Access"}
            </button>
          )}
        </div>
      )}

      {/* Business Plan */}
      {(selectedTab === "business" || window.innerWidth >= 768) && (
        <div className="pricing-card">
          <div className="min-h">
            <div className="pricing-header">Business</div>
            <div className="pricing-amount">
              {getBusinessPlan()?.currency}
              {getBusinessPlan()?.price} <span>/month</span>
            </div>
            <div className="slider-container">
              <div className="credits_span1">
                {getBusinessPlan()?.credits_to_dollar}
              </div>
              <Slider
                marks={businessMarks}
                value={businessSliderValue}
                onChange={setBusinessSliderValue}
                step={null}
                tooltip={{ formatter: null }}
              />
            </div>
            <div className="pricing-description">
              Features in business plan :
            </div>
            <div className="pricing-features">
              <ul>
                {businessFeatures.map((feature, index) => (
                  <li key={index}>
                    <img src={tick} alt="" /> {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {handleSubscribePlan ? (
            <UpgradePlan
              credits={subscriptionPlan?.credits_grant}
              isCurrentPlan={
                subscriptionPlan?.credits_grant ===
                getBusinessPlan()?.credits_grant
              }
              subscribePlan={() =>
                handleSubscribePlan(
                  getBusinessPlan()?.price,
                  getBusinessPlan()?.id,
                  setIsBusinessLoading
                )
              }
              isLoading={isBusinessLoading}
            />
          ) : (
            <button className="pricing-button" onClick={handleClick}>
              {"Get Started"}
            </button>
          )}
        </div>
      )}

      {(selectedTab === "enterprise" || window.innerWidth >= 768) && (
        <div className="pricing-card">
          {shouldShowContent ? (
            <>
              {!hasSubmitted ? (
                <div className="min-h">
                  <button
                    className="back-button"
                    onClick={resetFormAndErrors}
                    style={{ position: "unset" }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />{" "}
                    <b>Back to Pricing Page</b>
                  </button>
                  <br />
                  <div className="pricing-header">Custom Plan</div>
                  <div className="custom_plan_head">
                    Unlock all the features of both the Business and Enterprise
                    plans{" "}
                  </div>
                  <div className="pricing-features">
                    <div className="billing-form-data">
                      <p>
                        Share your details and customize your plan with us!{" "}
                      </p>
                      <form
                        name="billing-form"
                        className="job_details_from billing_form billing_data_form"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="form-row res-wd-mob">
                            <label
                              className="res-wd"
                              style={{
                                width: "100%",
                              }}
                            >
                              <input
                                name="fullName"
                                placeholder="Enter Full Name"
                                value={formData.fullName}
                                onChange={handleChange}
                                disabled={loading}
                              />
                              {formErrors.fullName && (
                                <div className="error">
                                  {formErrors.fullName}
                                </div>
                              )}
                            </label>
                          </div>
                          <div className="form-row res-wd-mob">
                            <label
                              className="res-wd"
                              style={{
                                width: "100%",
                              }}
                            >
                              <input
                                name="phone"
                                placeholder="Enter Contact Number"
                                value={formData.phone}
                                onChange={handleChange}
                                disabled={loading}
                              />
                              {formErrors.phone && (
                                <div className="error">{formErrors.phone}</div>
                              )}
                            </label>
                          </div>
                          <div className="form-row res-wd-mob">
                            <label
                              className="res-wd"
                              style={{
                                width: "100%",
                              }}
                            >
                              <input
                                name="email"
                                placeholder="Enter Email ID"
                                value={formData.email}
                                onChange={handleChange}
                                disabled={loading}
                              />
                              {formErrors.email && (
                                <div className="error">{formErrors.email}</div>
                              )}
                            </label>
                          </div>
                          <div className="ContactMe">
                            <button
                              type="submit"
                              className="pricing-button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {loading ? (
                                <ThreeCircles
                                  visible={true}
                                  height="20"
                                  width="20"
                                  color="#fff"
                                  ariaLabel="loading-indicator"
                                />
                              ) : (
                                "Contact Me"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="back-button"
                    onClick={resetFormAndErrors}
                    style={{ position: "unset" }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />{" "}
                    <b>Back to Pricing Page</b>
                  </button>
                  <br />
                  <div className="thankyou">
                    <div>
                      <img src={thick_right_icn} alt="Checkmark" />
                      <h3>Thank you!</h3>
                      <p>Our team will contact you soon.</p>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            // The default view with the min-h div
            <>
              <div className="min-h">
                <div className="pricing-header">Enterprise</div>
                <div className="pricing-amount">
                  {getEnterprisePlan()?.currency}
                  {getEnterprisePlan()?.price} <span>/month</span>
                </div>
                <div className="slider-container">
                  <div className="credits_span1">
                    {getEnterprisePlan()?.credits_to_dollar}
                  </div>
                  <Slider
                    marks={enterpriseMarks}
                    value={enterpriseSliderValue}
                    onChange={setEnterpriseSliderValue}
                    step={null}
                    tooltip={{ formatter: null }}
                  />
                </div>
                <div className="pricing-description">
                  Features in enterprise plan :
                </div>
                <div className="pricing-features">
                  <ul>
                    {enterpriseFeatures.map((feature, index) => (
                      <li key={index}>
                        <img src={tick} alt="" /> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {handleSubscribePlan ? (
                <UpgradePlan
                  credits={subscriptionPlan?.credits_grant}
                  isCurrentPlan={
                    subscriptionPlan?.credits_grant ===
                    getEnterprisePlan()?.credits_grant
                  }
                  subscribePlan={() =>
                    handleSubscribePlan(
                      getEnterprisePlan()?.price,
                      getEnterprisePlan()?.id,
                      setIsEnterpriseLoading
                    )
                  }
                  isLoading={isEnterpriseLoading}
                />
              ) : (
                <button className="pricing-button" onClick={handleClick}>
                  {"Get Started"}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Plans;
