import React from "react";
import { req_1, req_2, req_4, req_3 } from "../../../assets/images/images";
import { Link, useNavigate } from "react-router-dom";

const RecruitersWork = () => {
  const handleLinkClick = () => {
    localStorage.setItem("userType", "recruiter");
  };

  return (
    <section className="how_works_for_req">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="how_works_for_req_inner">
              <div
                className="how_works_for_req_inner_head wow animate__zoomIn"
                data-wow-duration="4s"
              >
                <h3>How Haire Works for Recruiters </h3>
              </div>
              <div className="how_works_for_req_inner_body_sec">
                <div
                  className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                  data-wow-duration="4s"
                >
                  <div className="how_works_for_req_inner_body_sec_single_img">
                    <img src={req_1} />
                  </div>
                  <div className="how_works_for_req_inner_body_sec_single_cont">
                    <h4>Sign Up as a Recruiter</h4>
                  </div>
                </div>
                <div
                  className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                  data-wow-duration="4s"
                >
                  <div className="how_works_for_req_inner_body_sec_single_img">
                    <img src={req_2} />
                  </div>
                  <div className="how_works_for_req_inner_body_sec_single_cont">
                    <h4>Post a Job Using AI</h4>
                  </div>
                </div>
                <div
                  className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                  data-wow-duration="4s"
                >
                  <div className="how_works_for_req_inner_body_sec_single_img">
                    <img src={req_3} />
                  </div>
                  <div className="how_works_for_req_inner_body_sec_single_cont">
                    <h4>Use Haire credits for AI Interview</h4>
                  </div>
                </div>
                <div
                  className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                  data-wow-duration="4s"
                >
                  <div className="how_works_for_req_inner_body_sec_single_img">
                    <img src={req_4} />
                  </div>
                  <div className="how_works_for_req_inner_body_sec_single_cont">
                    <h4>Shortlist Candidate</h4>
                  </div>
                </div>
              </div>
              <div
                className="sign_up_req_btn_sec wow animate__zoomIn"
                data-wow-duration="4s"
              >
                <Link
                  to={{
                    pathname: "/sign-up",
                    state: { userType: "recruiter" },
                  }}
                  onClick={handleLinkClick}
                >
                  Sign Up as Recruiter
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecruitersWork;
