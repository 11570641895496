import { Modal, Button } from "antd";
import screenRecordingStore from "../../../../zustand/screenRecordStore";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import screenRecordingSessionStore from "../../../../zustand/screenRecordingSessionStore";
import { toast } from "react-toastify";

const ScreenSharePermissionPopup = () => {
    const { showModal, resumeScreenSharing } = screenRecordingStore();
    const { interviewId } = useParams();
    const token = useSelector((state) => state?.auth?.token);
    const { uploadChunk } =
        screenRecordingSessionStore();

    const handleResumeScreenSharing = () => {
        try {
            resumeScreenSharing(
                token,
                interviewId,
                uploadChunk,
                (message) => {
                    toast.error(message, {
                        position: "top-right",
                        duration: 4000,
                    });
                }
            );
        } catch (error) {
            console.error("Failed to resume screen sharing:", error);

            toast.error("Failed to resume screen sharing. Please try again.", {
                position: "top-right",
                duration: 4000,
            });
        }
    }

    return (
        <Modal
            title="Screen Sharing Stopped"
            open={showModal}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="resume" type="primary" onClick={handleResumeScreenSharing}>
                    Resume Screen Sharing
                </Button>,
            ]}
        >
            <p>Screen sharing has stopped. Please enable it to continue the interview</p>
        </Modal>
    );
};

export default ScreenSharePermissionPopup;
