import React, { useState } from 'react'
import TryBtn from '../NewSections/TryBtn'
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";

const NewDataDriven = () => {
    const [activeIndex, setActiveIndex] = useState(2);

  return (
    <>
      <section className="data_driven_sec_new padd_0">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="">
                <div className="remark_sec_new_inner">
                  {[
                    { id: 1, title: "Save Time", subtitle: "On initial candidate screening", percent: 70 },
                    { id: 2, title: "Save Money", subtitle: "On overall recruitment costs", percent: 50 },
                    { id: 3, title: "Save Resources", subtitle: "On recruitment costs", percent: 40 },
                    { id: 4, title: "Reduce Hiring Timeline", subtitle: "In recruitment process", percent: 50 }
                  ].map((item, index) => (
                    <div
                      key={item.id}
                      className={`remark_sec_new_inner_single wow animate__slideInLeft ${activeIndex === item.id ? 'active' : ''}`}
                      data-wow-duration="4s"
                      onMouseEnter={() => setActiveIndex(item.id)} 
                    >
                      <h6>{item.percent}%</h6>
                      <p className="card_title">{item.title}</p>
                      <p>{item.subtitle}</p>
                    </div>
                  ))}
                </div>
                <TryBtn/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewDataDriven
