import { Button, Form, Modal, Steps, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import {
  createJob,
  fetchSingleJobDetails,
  updateJob,
} from "../../../../redux/actions/jobActions";
import {
  resetJobDraftState,
  setDraftSaved,
  updateJobDetails,
} from "../../../../redux/reducers/jobReducer";
import PostJobModal from "../CommonRecruiterComponant/PostJobModal";
import "./JobDetails.css";
import JobDetailsForm1 from "./JobDetailsForm1";
import JobDetailsFrom2 from "./JobDetailsForm2";
import JobDetailsForm4 from "./JobDetailsForm4";
import { ThreeCircles } from "react-loader-spinner";
import {
  getKeyByValue,
  getStatusId,
  isPositiveInteger,
  scrollToFirstError,
} from "../../../../utils/utils";
import { toast } from "react-toastify";
import JobDetailsFrom3 from "./JobDetailsForm3";
import dayjs from "dayjs";
import { zeroCreditError } from "../../../../utils/constants";
import { linkedin, mail_icn, wp_icn } from "../../../../assets/images/images";
import PricingModal from "../Monitization/components/PricingModal";
import { usePricingModal } from "../../../../contexts/PricingModalContext";

const JobDetails = () => {
  const { token } = theme.useToken();
  const location = useLocation();
  const jobID = useParams();
  const { isPricingModalOpen, hidePricingModal } = usePricingModal();
  const jobId = useSelector((state) => state?.job?.details?.id);
  const [current, setCurrent] = useState(0);
  const [jobPosted, setJobPosted] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const jobDetails = useSelector((state) => state?.job?.details);
  const jobLoading = useSelector((state) => state?.job?.loading);
  const authToken = useSelector((state) => state?.auth?.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showShareJobModal, setShowShareJobModal] = useState(false);
  const [isPostJobModalVisible, setIsPostJobModalVisible] = useState(false);
  const companyCredits = useSelector(
    (state) => state?.company?.details?.company_credits
  );
  const currencies = useSelector((state) => state.job.currencies);

  const [form] = Form.useForm();

  const steps = [
    {
      title: "",
      content: "1",
      component: <JobDetailsForm1 form={form} />,
    },
    {
      title: "",
      content: "2",
      component: (
        <JobDetailsFrom2 form={form} setDisabledBtn={setDisabledBtn} />
      ),
    },
    {
      title: "",
      content: "3",
      component: (
        <JobDetailsFrom3
          form={form}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
        />
      ),
    },
    {
      title: "",
      content: "4",
      component: <JobDetailsForm4 />,
    },
  ];

  const JobDetailsPayload = {
    ...jobDetails,
    job_link: undefined,
    currency:
    getKeyByValue(currencies, form.getFieldValue("currency")) !== undefined
      ? getKeyByValue(currencies, form.getFieldValue("currency"))
      : form.getFieldValue("currency"),
    custom_end_date:
      form.getFieldValue("custom_end_date") !== null
        ? dayjs(form.getFieldValue("custom_end_date")).format("YYYY-MM-DD")
        : "",
  };

  let buttonText;

  if (location.pathname.startsWith("/dashboard/job-details/")) {
    buttonText = current === steps.length - 1 ? "Save as Draft" : "Next";
  } else if (location.pathname.startsWith("/dashboard/my-jobs/jobEdit")) {
    buttonText = current === steps.length - 1 ? "Update a job" : "Next";
  } else if (location.pathname.startsWith("/dashboard/my-jobs/draftJobs/")) {
    buttonText = current === steps.length - 1 ? "Post a job" : "Next";
  } else {
    buttonText = current === steps.length - 1 ? "Save as Draft" : "Next";
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToTop();
    }, 100); // 100 milliseconds delay
    return () => clearTimeout(timer);
  }, [current]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  const scrollToTop = () => {
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  const postJob = () => {
    if (current === steps.length - 1) {
      if (companyCredits === 0) {
        toast.error(zeroCreditError);
      } else {
        setIsPostJobModalVisible(true);

        const updatedJobDetails = {
          ...jobDetails,
          custom_end_date:
            form.getFieldValue("custom_end_date") !== null
              ? dayjs(form.getFieldValue("custom_end_date")).format(
                  "YYYY-MM-DD"
                )
              : "",
        };

        dispatch(createJob(authToken, updatedJobDetails, "2", navigate))
          .then((response) => {
            if (
              !response?.data?.content_check_required ||
              !response?.data?.industry_classification
            ) {
              Modal.confirm({
                title: "Attention required",
                width: 600,
                content: (
                  <div>
                    <p>{response?.data?.content_info}</p>
                    <h5>Instructions</h5>
                    <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        Your job post couldn't be published due to the mentioned
                        reason.
                      </li>
                      <li>
                        To rectify the issues, click on "Edit" to make necessary
                        changes.
                      </li>
                      <li>
                        If you click "Cancel," you'll remain on the same page.
                      </li>
                      <li>
                        You can find this job post under the "Drafts" section
                        for future edits.
                      </li>
                    </ul>
                  </div>
                ),
                okText: "Edit job",
                onOk: () => {
                  dispatch(
                    fetchSingleJobDetails(response?.data?.id, authToken, true)
                  );
                  navigate(
                    `/dashboard/my-jobs/jobEdit/${response?.data?.id}/edit`
                  );
                },
              });
            } else {
              navigate("/dashboard", {
                state: { showShareJobModal: true, slug: response.data.slug },
                replace: true,
              });
              message.success("Posted Job successfully!");
              setShowShareJobModal(true);
            }
            setJobPosted(true);
          })
          .catch((error) => {
            setJobPosted(true);
          });
      }
    } else {
      form
        .validateFields()
        .then(() => {
          setCurrent(current + 1);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    const cleanup = () => {
      if (jobPosted) {
        dispatch(resetJobDraftState());
        setJobPosted(false);
      }
    };

    return cleanup;
  }, [jobPosted, dispatch]);

  useEffect(() => {
    scrollToTop();
  }, [current]);

  const creditsAlloted = form.getFieldValue("credits_alloted");
  const budget = form.getFieldValue("budget");
  const customEndDate = form.getFieldValue("custom_end_date");

  const hasValidCredits =
    isPositiveInteger(creditsAlloted) && creditsAlloted <= companyCredits;
  const hasValidBudgetAndDate =
    budget === 1 && hasValidCredits && customEndDate;

  const next = () => {
    form
      .validateFields()
      .then(() => {
        if (current === steps.length - 2) {
          if (
            (hasValidCredits || hasValidBudgetAndDate) &&
            !isNextButtonDisabled
          ) {
            setCurrent(current + 1);
            scrollToTop();
          }else{
            toast.error("Alloted credits are lesser than company credits");
          }
        } else {
          if (current < steps.length - 1 && !isNextButtonDisabled) {
            setCurrent(current + 1);
            scrollToTop();
          }
        }
      })
      .catch((errorInfo) => {
        scrollToFirstError(errorInfo?.errorFields);
      });
  };

  const saveAsDraft = async () => {
    try {
      await form.validateFields();

      let actionMessage, actionType, actionPayload;

      const isDashboardJobDetails = location.pathname.startsWith(
        "/dashboard/job-details"
      );

      const isEditingDraftJob =
        location.pathname.startsWith("/dashboard/my-jobs/draftJobs/") &&
        location.pathname.endsWith("/edit");

      switch (true) {
        // post job as draft job
        case isDashboardJobDetails:
          actionMessage = "Draft saved successfully!";
          actionType = "1";
          actionPayload = createJob(
            authToken,
            JobDetailsPayload,
            actionType,
            navigate
          );
          setDraftSaved(jobDetails);
          break;

        //update job the inactive job and post the job
        case location.pathname.startsWith("/dashboard/my-jobs/jobEdit"):
          if (JobDetailsPayload?.status === "Inactive") {
            actionMessage = "Job Updated Successfully!";
            actionType = 2;
            actionPayload = updateJob(
              authToken,
              jobId,
              JobDetailsPayload,
              navigate,
              actionType
            );
            break; // Break here to prevent the next case from running
          }

          // update job as per the status (this will only execute if the above `if` is false)
          actionMessage = "Job Updated Successfully!";
          actionType = getStatusId(JobDetailsPayload?.status);
          actionPayload = updateJob(
            authToken,
            jobId,
            JobDetailsPayload,
            navigate,
            actionType
          );
          break;

        //update job as per the status
        case location.pathname.startsWith("/dashboard/my-jobs/jobEdit"):
          actionMessage = "Job Updated Successfully!";
          actionType = getStatusId(JobDetailsPayload?.status);
          actionPayload = updateJob(
            authToken,
            jobId,
            JobDetailsPayload,
            navigate,
            actionType
          );
          break;

        case isEditingDraftJob:
          actionMessage = "Draft job Posted successfully!";
          actionType = "2";
          const urlParts = location.pathname.split("/");
          const draftJobId = urlParts[urlParts.length - 2]; // Assuming the job ID is the second last part of the URL
          actionPayload = updateJob(
            authToken,
            draftJobId, // Make sure this correctly references the job ID
            JobDetailsPayload,
            navigate,
            actionType,
            location
          );
          setDraftSaved(jobDetails);
          break;

        //update the draft job with the and post the job
        case location.pathname.startsWith(
          `/dashboard/my-jobs/draftJobs/${jobID}/edit`
        ):
          actionMessage = "Your job is posted Successfully!";
          actionType = "2";
          actionPayload = updateJob(
            authToken,
            jobID,
            JobDetailsPayload,
            navigate,
            actionType
          );
          break;

        //update the closed job with the same status code
        case location.pathname.startsWith("/dashboard/my-jobs/closedJobs/"):
          actionMessage = "Your job is posted successfully!";
          actionType = getStatusId(JobDetailsPayload?.status);
          actionPayload = updateJob(
            authToken,
            jobId,
            JobDetailsPayload,
            navigate,
            actionType
          );
          break;

        default:
          actionMessage = "Draft saved successfully!";
          actionType = "1";
          actionPayload = setDraftSaved(jobDetails);
      }

      dispatch(actionPayload).then((response) => {
        message.success(actionMessage);
        dispatch(resetJobDraftState());
      });
    } catch (error) {
      message.error("Failed to save draft. Please check your form.");
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    lineHeight: "inherit",
    textAlign: "center",
    color: token.colorTextTertiary,
    borderRadius: token.borderRadiusLG,
    border: `0px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const handleBackButtonClick = () => {
    Modal.confirm({
      title: "Are you sure you want to go back?",
      content:
        "If you go back now, you will lose any unsaved data. Do you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { className: "customYesButton" },
      cancelButtonProps: { className: "customNoButton" },
      onOk: () => {
        dispatch(resetJobDraftState());

        const jobEditPattern = /^\/dashboard\/my-jobs\/jobEdit\/(\d+)\/edit$/;
        const match = location.pathname.match(jobEditPattern);

        if (match) {
          const jobId = match[1];
          navigate(`/dashboard/my-jobs/myJobs/${jobId}/view-jobs-data`, {
            replace: true,
          });
        } else if (
          location.pathname.includes("/dashboard/my-jobs/draftJobs/") &&
          location.pathname.includes("/edit")
        ) {
          navigate("/dashboard/my-jobs/draftJobs", { replace: true });
        } else {
          navigate("/dashboard", { replace: true });
        }
      },

      onCancel: () => {},
    });
  };

  const backButtonText = location.pathname.includes("/dashboard/job-details")
    ? "Back to Dashboard"
    : "Back ";
  const handleFormChange = (_, allValues) => {
    dispatch(updateJobDetails(allValues));
  };

  return (
    <>
      <section className="job_det_step_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="job_det_step_sec_new_inner">
                <div className="row  justify-content-center">
                  <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 pad-0">
                    <div className="job_det_step_inner_step_new">
                      <DashboardHeader
                        title="Job Details"
                        backButtonLink={handleBackButtonClick}
                        backButtonText={backButtonText}
                      />

                      <Steps
                        current={current}
                        items={items}
                        responsive={true}
                      />

                      {
                        <div style={contentStyle}>
                          {steps[current].component}
                        </div>
                      }

                      <div className="" style={{ marginTop: 24 }}>
                        <div className="apply-job-btn-container next-btn-container">
                          <div>
                            {current > 0 && (
                              <Button
                                disabled={jobLoading || disabledBtn}
                                onClick={() => {
                                  prev();
                                  setDisabledBtn(false);
                                  setIsNextButtonDisabled(false);
                                }}
                              >
                                Back
                              </Button>
                            )}
                          </div>
                          <div className="next-btn-container">
                            <Button
                              type="primary"
                              disabled={
                                jobLoading ||
                                disabledBtn ||
                                isNextButtonDisabled
                              }
                              onClick={
                                current === steps.length - 1
                                  ? saveAsDraft
                                  : next
                              }
                              onValuesChange={handleFormChange}
                            >
                              {jobLoading ? (
                                <ThreeCircles
                                  visible={true}
                                  height="20"
                                  width="20"
                                  color="#fff"
                                  ariaLabel="loading-indicator"
                                />
                              ) : (
                                buttonText
                              )}
                            </Button>
                            {current === steps.length - 1 &&
                              !location.pathname.startsWith(
                                "/dashboard/my-jobs"
                              ) && (
                                <Button
                                  className=""
                                  type="primary"
                                  onClick={postJob}
                                  onValuesChange={handleFormChange}
                                  disabled={jobLoading}
                                >
                                  {jobLoading ? (
                                    <ThreeCircles
                                      visible={true}
                                      height="20"
                                      width="20"
                                      color="#1865c1"
                                      ariaLabel="loading-indicator"
                                    />
                                  ) : (
                                    "Post a Job"
                                  )}
                                </Button>
                              )}
                          </div>
                        </div>
                        <Modal
                          centered
                          open={isPostJobModalVisible}
                          onOk={() => setIsPostJobModalVisible(false)}
                          onCancel={() => setIsPostJobModalVisible(false)}
                          closable={false}
                          footer={null}
                        >
                          <PostJobModal />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PricingModal show={isPricingModalOpen} onHide={hidePricingModal} />
    </>
  );
};

export default JobDetails;
