import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const DashboardHeader = ({
  title,
  backButtonLink,
  backButtonText = "Back",
  style,
}) => {
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <div className="header" style={style}>
      <button
        className="back-button"
        onClick={backButtonLink ?? handleBackButtonClick}
      >
        <FontAwesomeIcon icon={faAngleLeft} /> <b>{backButtonText}</b>
      </button>
      <h1 className="header-title">{title}</h1>
    </div>
  );
};

export default DashboardHeader;
