import React, { useEffect, useState } from 'react';
import "../Home/home.css";
import "../Home/home_responsive.css";
import "../Home/New_Home.css";
import { Helmet } from 'react-helmet';
import "animate.css/animate.min.css";
import HaireVideo from './NewSections/HaireVideo';
import { WOW } from "wowjs";
import "animate.css/animate.min.css";
import NewBanner from './NewLandingSections/NewBanner';
import NewDataDriven from './NewLandingSections/NewDataDriven';
import NewTrustPartner from './NewLandingSections/NewTrustPartner';
import WhyHaire from './NewLandingSections/WhyHaire';
import IntegrationSupport from './NewLandingSections/IntegrationSupport';
import HowWorks from './NewLandingSections/HowWorks';
import NewRecrutierTestimonials from './NewLandingSections/NewRecrutierTestimonials';
import NewCandidateTestimonials from './NewLandingSections/NewCandidateTestimonials';
import NewKeyFeatures from './NewLandingSections/NewKeyFeatures';
import NewSkip from './NewLandingSections/NewSkip';
import NewFooter from './NewLandingSections/NewFooter';

const HomeLanding = () => {
  useEffect(() => {
      new WOW().init();
    }, []);
  

  return (
    <>
        <Helmet>
            <title>
            AI Video Interview Platform | AI Based Recruitment Platform - Haire
            </title>
            <meta
            name="description"
            content="Haire:Unleash the power of AI with the best AI Recruitment Platform. Hire employees with less time & cost with the AI interview software. Know More"
            />
        <link rel="canonical" href="https://haire.ai/" />
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
      </Helmet>

      <NewBanner />

      <HaireVideo/>

      <hr className='spacer60px'/>

      <NewDataDriven/>

      <NewTrustPartner />
      
      <WhyHaire />
      
      <IntegrationSupport />
      
      <HowWorks />
      <hr className='spacer30px'/>

      <NewRecrutierTestimonials />

      <NewKeyFeatures />

      <NewCandidateTestimonials />

      <NewSkip />
      
      <NewFooter/>
      
    </>
  )
}

export default HomeLanding
