import React, { useEffect, useState } from "react";
import { resumeicon } from "../../../../assets/images/images";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "../JobDetails/JobDetails.css";

const Profile = () => {
  const applicant = useSelector((state) => state?.job?.applicationResult);

  const getResumeFileExtension = (fileName) => {
    const ext = fileName?.split(".").pop().toLowerCase();
    switch (ext) {
      case "doc":
      case "docx":
        return "docx";
      case "pdf":
        return "pdf";
      case "png":
        return "png";
      case "jpg":
      case "jpeg":
        return "jpg";
      default:
        return ext;
    }
  };

  useEffect(() => {
    console.log(applicant);
  }, []);

  return (
    <>
      <div className="Transcript_main">
        <div className="profile-header">
          <div className="profile_name">
            Profile: {applicant?.user_full_name}
          </div>
          {applicant?.status === "Fit-For-Job" ? (
            <div className="resumePdf">N/A</div>
          ) : (
            <div className="resumePdf">
              <img src={resumeicon} alt="" />
              <a
                className="pdf"
                href={applicant?.resume}
                download={`${
                  applicant?.user_full_name
                }_Resume.${getResumeFileExtension(applicant?.resume)}`}
              >
                {applicant?.user_full_name}_Resume.
                {getResumeFileExtension(applicant?.resume)}
              </a>
            </div>
          )}
        </div>
        <span className="bold_text">Current Annual Compensation</span>
        <div className="profile-section profile-ctc">
          <div className="profile-ctc-value">
            {applicant?.currency} {applicant?.current_ctc}
          </div>
        </div>
        {applicant?.ctc_options ? ( // Checks if ctc is not null and not undefined
          <div>
            <strong className="bold_text">Annual Compensation Breakdown</strong>
            <div className="profile-section profile_base">
              {applicant?.ctc_options}
            </div>
          </div>
        ) : null}

        {applicant?.physically_challenged ? ( // Checks if ctc is not null and not undefined
          <div>
            <strong className="bold_text">Assistance Benefits</strong>
            <div className="profile-section profile_base">
              {applicant?.physically_challenged}
            </div>
          </div>
        ) : null}

        <strong className="bold_text">Resignation Notice</strong>
        <div className="profile-section profile-notice">
          {applicant?.notice_period}
        </div>
      </div>
    </>
  );
};

export default Profile;
