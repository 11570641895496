import React from 'react'
import TryBtn from '../NewSections/TryBtn';
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";
import { land_img_1 } from "../../../assets/images/images";

const IntegrationSupport = () => {
    
  return (
    <>
      <section className='inte_suu_ai'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='inte_suu_ai_inner'>
                <div className='inte_suu_ai_inner_head wow animate__zoomIn' data-wow-duration="4.2s">
                  <h6>Integration Support with ATS</h6>
                  <p>We support integration with various ATS platforms, streamlining the smooth flow of data with Haire.ai</p>
                </div>
                <div className='inte_suu_ai_inner_body wow animate__zoomIn' data-wow-duration="4.6s">
                    <div className='inte_suu_ai_inner_body_inner_img'>
                      <img src={land_img_1} />
                    </div>
                    <TryBtn/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IntegrationSupport
