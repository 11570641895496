import { useState, useEffect, useCallback } from "react";

const useEnhanceFullResumeSocket = (url) => {
  const [socket, setSocket] = useState(null);
  const [fullmessages, setFullMessages] = useState([]);
  const [socketLoadingForFullResume, setSocketLoadingForFullResume] = useState(false);

  useEffect(() => {
    const webSocket = new WebSocket(url);

    webSocket.onopen = () => {
      console.log("WebSocket connected");
    };

    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log('enhanced_resume data show : ',data.enhanced_resume);
      if (data.enhanced_resume) {
        setFullMessages(data.enhanced_resume);
        setSocketLoadingForFullResume(false);
      }
    };

    webSocket.onclose = (event) => {
      console.log("WebSocket disconnected.",
        "Code:", event.code,
        "Reason:", event.reason || "No reason provided.");
        setSocketLoadingForFullResume(false); // Reset loading on close
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setSocketLoadingForFullResume(false); // Reset loading on error
    };

    setSocket(webSocket); // Update state with WebSocket instance

    // Cleanup function to close WebSocket on unmount
    return () => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close();
      }
    };
  }, [url]);

  const sendFullMessage = useCallback(
    (message) => {
      if (socket?.readyState === WebSocket.OPEN) {
        setSocketLoadingForFullResume(true);
        socket.send(JSON.stringify(message));
      }
    },
    [socket]
  );

  const clearFullResumeMessages = useCallback(() => {
    setFullMessages([]);
  }, []);

  return { fullmessages, sendFullMessage, clearFullResumeMessages, socketLoadingForFullResume };
};

export default useEnhanceFullResumeSocket;
