import { Modal } from "antd";
import { close_job } from "../../../../../assets/images/images";

const CustomPopup = ({
    isModalVisible,
    handleCancel,
    handleConfirm,
    question,
    subtitle,
  }) => {
    return (
      <Modal
        className="interview_modal_sm"
        centered
        open={isModalVisible}
        closable={false}
        footer={[
          <div className="inter_panel_ready_sec_inner_btns">
            <button type="button" className="no_btn_int" onClick={handleCancel}>
              No
            </button>
            <button type="button" className="yes_btn_int" onClick={handleConfirm}>
              Yes
            </button>
          </div>,
        ]}
        width={800}
      >
        <div className="inter_panel_ready_sec_inner">
          <img src={close_job} alt="" />
          <h6>{question}</h6>
          <p>{subtitle}</p>
        </div>
      </Modal>
    );
  };

  export default CustomPopup;
  