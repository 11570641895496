import { create } from "zustand";

interface ScreenRecordingState {
    isRecording: boolean;
    startScreenRecording: (
        token: string,
        interviewId: string,
        uploadChunk: Function,
        onPermissionGranted?: () => void,
        showToast?: (message: string) => void
    ) => Promise<void>;
    stopScreenRecording: () => void;
    screenRecorderRef: MediaRecorder | null;
    recordingStartTime: number | null;
    showModal: boolean;
    isScreenSelectionInProgress: boolean;
    setShowModal: (value: boolean) => void;
    resumeScreenSharing: (token: string,
        interviewId: string,
        uploadChunk: Function,
        showToast?: (message: string) => void) => void;
}

// Detect browser type
export const detectBrowser = (): 'firefox' | 'other' => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('firefox') ? 'firefox' : 'other';
};

const screenRecordingStore = create<ScreenRecordingState>((set) => ({
    isRecording: false,
    screenRecorderRef: null,
    recordingStartTime: null,
    showModal: false,
    setShowModal: (value: boolean) => set({ showModal: value }),
    isScreenSelectionInProgress: false,

    startScreenRecording: async (
        token: string,
        interviewId: string,
        uploadChunk: Function,
        onPermissionGranted?: () => void,
        showToast?: (message: string) => void
    ) => {
        set({ isScreenSelectionInProgress: true });
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: {
                    displaySurface: 'monitor',
                } as any,
            }).catch((error) => {
                // Handle permission denial or cancellation
                set({ isScreenSelectionInProgress: false });
                if (showToast) {
                    showToast("Screen sharing is required to continue the interview. Please enable screen sharing.");
                }
                throw error;
            });

            const videoTrack = stream.getVideoTracks()[0];
            const settings = videoTrack.getSettings() as MediaTrackSettings & { displaySurface?: string };

            const browser = detectBrowser();

            // Browser-specific screen selection logic
            if (browser === 'firefox') {
                // Get screen dimensions safely
                const screenWidth = window.screen.width || window.innerWidth;
                const screenHeight = window.screen.height || window.innerHeight;

                // Check if selected area is approximately full screen
                const isFullScreen =
                    settings.width && settings.height &&
                    settings.width >= screenWidth * 0.95 &&
                    settings.height >= screenHeight * 0.95;

                if (!isFullScreen) {
                    set({ showModal: true, isScreenSelectionInProgress: false });
                    stream.getTracks().forEach(track => track.stop());
                    if (showToast) {
                        showToast("Please select the entire screen to continue.");
                    }
                    return;
                }
            } else {
                // For Chrome, Brave, Edge, Safari
                if (settings.displaySurface !== 'monitor') {
                    set({ showModal: true, isScreenSelectionInProgress: false });
                    stream.getTracks().forEach(track => track.stop());
                    if (showToast) {
                        showToast("Please select the entire screen to continue.");
                    }
                    return;
                }
            }

            // Request microphone audio separately
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
                video: false,
            });

            // Only call onPermissionGranted if we've successfully got both streams
            if (onPermissionGranted) {
                onPermissionGranted();
            }

            // Combine the streams
            const combinedStream = new MediaStream([
                ...stream.getVideoTracks(),
                ...stream.getAudioTracks(),
                ...audioStream.getAudioTracks(),
            ]);

            // Select MIME type based on browser
            const mimeType = browser === 'firefox'
                ? "video/webm;codecs=vp8,opus"
                : "video/mp4";

            // Initialize MediaRecorder
            const mediaRecorder = new MediaRecorder(combinedStream, {
                mimeType: mimeType,
                videoBitsPerSecond: 100000,
            });

            const chunks: Blob[] = [];

            mediaRecorder.onstart = () => {
                set({ recordingStartTime: Date.now() });
            };

            mediaRecorder.ondataavailable = async (e: BlobEvent) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                    await uploadChunk(token, e.data, interviewId);
                }
            };

            set({ screenRecorderRef: mediaRecorder, isRecording: true, showModal: false });
            mediaRecorder.start(5000);
            mediaRecorder.requestData();

            // Monitor screen sharing stream tracks
            stream.getTracks().forEach(track => {
                track.onended = async () => {
                    set({ showModal: true });
                };
            });

            // Reset the flag after screen selection
            set({ isScreenSelectionInProgress: false });

        } catch (error) {
            console.error('Error starting screen recording:', error);
            set({ isScreenSelectionInProgress: false, showModal: true });
        }
    },

    stopScreenRecording: () => {
        const { screenRecorderRef, isRecording } = screenRecordingStore.getState();

        if (screenRecorderRef && isRecording) {
            // Stop the media recorder
            screenRecorderRef.stop();

            // Stop all tracks of the MediaStream (both audio and video)
            const tracks = screenRecorderRef.stream.getTracks();
            tracks.forEach((track) => track.stop());

            // Reset state after stopping
            set({ isRecording: false, screenRecorderRef: null });
        }
    },

    resumeScreenSharing: async (
        token: string,
        interviewId: string,
        uploadChunk: Function,
        showToast?: (message: string) => void
    ) => {
        set({ isScreenSelectionInProgress: true });
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: {
                    displaySurface: 'monitor',
                } as any,
            }).catch((error) => {
                // Handle permission denial or cancellation
                set({ isScreenSelectionInProgress: false });
                if (showToast) {
                    showToast("Screen sharing is required to continue the interview. Please enable screen sharing.");
                }
                throw error;
            });

            const videoTrack = stream.getVideoTracks()[0];
            const settings = videoTrack.getSettings() as MediaTrackSettings & { displaySurface?: string };

            const browser = detectBrowser();

            // Browser-specific screen selection logic
            if (browser === 'firefox') {
                // Get screen dimensions safely
                const screenWidth = window.screen.width || window.innerWidth;
                const screenHeight = window.screen.height || window.innerHeight;

                // Check if selected area is approximately full screen
                const isFullScreen =
                    settings.width && settings.height &&
                    settings.width >= screenWidth * 0.95 &&
                    settings.height >= screenHeight * 0.95;

                if (!isFullScreen) {
                    set({ showModal: true, isScreenSelectionInProgress: false });
                    stream.getTracks().forEach(track => track.stop());
                    if (showToast) {
                        showToast("Please select the entire screen to continue.");
                    }
                    return;
                }
            } else {
                // For Chrome, Brave, Edge, Safari
                if (settings.displaySurface !== 'monitor') {
                    set({ showModal: true, isScreenSelectionInProgress: false });
                    stream.getTracks().forEach(track => track.stop());
                    if (showToast) {
                        showToast("Please select the entire screen to continue.");
                    }
                    return;
                }
            }

            // Request microphone audio separately
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
                video: false,
            });

            // Combine the streams
            const combinedStream = new MediaStream([
                ...stream.getVideoTracks(),
                ...stream.getAudioTracks(),
                ...audioStream.getAudioTracks(),
            ]);

            // Select MIME type based on browser
            const mimeType = browser === 'firefox'
                ? "video/webm;codecs=vp8,opus"
                : "video/mp4";

            // Initialize MediaRecorder
            const mediaRecorder = new MediaRecorder(combinedStream, {
                mimeType: mimeType,
                videoBitsPerSecond: 100000,
            });

            const chunks: Blob[] = [];

            mediaRecorder.onstart = () => {
                set({ recordingStartTime: Date.now() });
            };

            mediaRecorder.ondataavailable = async (e: BlobEvent) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                    await uploadChunk(token, e.data, interviewId);
                }
            };

            mediaRecorder.start(2000);
            set({ screenRecorderRef: mediaRecorder, showModal: false, isRecording: true });

            stream.getTracks().forEach(track => {
                track.onended = () => set({ showModal: true });
            });
            set({ isScreenSelectionInProgress: false });
        } catch (error) {
            console.error('Error resuming screen sharing:', error);
            set({ isScreenSelectionInProgress: false });
        }
    }
}));

export default screenRecordingStore;