import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { clock_icon, haire_logo } from "../../assets/images/images";
import {
  calculateTimeSpan,
  formatDateTimeToLocalWithOffset,
  formatDurationTime,
  parseTimeToSeconds,
} from "../utils";
import { addWatermarkToAllPages } from "./addWaterMark";

export const generateDemoPdf = async (
  transcripts,
  demoJobsInfo,
  demoJobsInfoList
) => {
  const fullName = demoJobsInfo?.full_name || "Candidate";

  const content = transcripts.map((item, index) => ({
    questionText: item?.ai_question,
    answerText: item?.candidate_answer,
    TimeToStartAnswer: parseTimeToSeconds(item?.time_to_start_answer),
    interpretationText: item?.ai_interpretation,
    question: `Question ${index + 1}: Hairo AI`,
    answer: `Answer : ${fullName}`,
    answerDuration: item?.answer_duration
      ? formatDurationTime(item?.answer_duration)
      : null,
  })); // Reverse the order of the content

  const pdf = new jsPDF();
  const container = document.createElement("div");
  container.style.width = "800px";
  container.style.margin = "0 auto";
  container.style.padding = "0";

  // Append container to document body for html2canvas usage
  document.body.appendChild(container);

  let yOffset = 10; // Initial y offset
  let isFirstPage = true;

  const interviewDuration = calculateTimeSpan(
    demoJobsInfoList?.interview?.started_at,
    demoJobsInfoList?.interview?.ended_at
  );
  const questionCount = transcripts.length;
  const timestamp =
    demoJobsInfoList?.interview?.date && demoJobsInfoList?.interview?.started_at
      ? formatDateTimeToLocalWithOffset(
          demoJobsInfoList?.interview?.date,
          demoJobsInfoList?.interview?.started_at
        )
      : "Timestamp unknown";

  // Add watermark to each page after it's created
  // const addWatermarkToAllPages = () => {
  //   const pageCount = pdf.internal.getNumberOfPages();
  //   for (let i = 1; i <= pageCount; i++) {
  //     pdf.setPage(i);
  //     addWatermarkToCurrentPage(pdf);
  //   }
  // };

  for (const data of content) {
    const element = document.createElement("div");
    element.style.backgroundColor = "#fff";
    element.style.fontFamily = "Arial, sans-serif";
    element.style.fontSize = "14px";
    element.style.lineHeight = "1.6";
    element.style.color = "#333";
    element.style.padding = "0px";
    element.style.pageBreakInside = "avoid";
    element.style.marginBottom = "10px"; // Reduced margin-bottom for spacing

    if (isFirstPage) {
      if (data.TimeToStartAnswer > 15) {
        element.innerHTML = `
        <div style="display: flex;">
          <img src="${haire_logo}" style="width: 80px; height: 40px; margin-right: 20px;">
          <div style="text-align: right; flex-grow: 1;">
            <div style="font-family: helvetica; font-weight: bold; font-size: 16px;">${fullName} | ${
          demoJobsInfo.job_profile
        }</div>
            <div style="font-family: helvetica; font-weight: normal; font-size: 14px;">${timestamp}</div>
          </div>
        </div>
        <div>
          <div style="padding: 0;">
            <div style="font-size: 12px; font-weight: bold; color: #333; margin-bottom: 0;">Interview Transcript Report</div>
            <div style="font-size: 18px; color: #000; font-weight: bold; margin-bottom: 0;">${
              demoJobsInfo.job_profile
            }</div>
            <div style="font-size: 14px; color: #000; display: flex; align-items: center;">
              <div style="margin-right: 10px; color: #000;">⏱ ${interviewDuration}</div>
              <div style="color: #000;">📋 ${questionCount} Questions</div>
            </div>
            <hr style="margin-top: 10px; border: 0; border-top: 5px solid #1865C1;" />
          </div>
          <div style=" #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${
              data.question
            }</h2>
            <p>${data.questionText}</p>
          </div>
         <div style="padding-left: 10px; margin-top: 10px; display:flex; flex-direction: column; gap : 10px; align-items: center; >
          <div display:flex;flex-direction: row;font-size: 16px; color: #0085FF; margin: 0 0 8px 0; font-weight: bold;>
            <h2 style="font-size: 16px; color: #0085FF; margin: 0 0 8px 0; font-weight: bold;">${
              data.answer
            }</h2> 
           <div style="width:fit-content; background-color: rgba(148, 27, 0, 0.1);border-radius:3px ; padding: 2px 10px;  color:rgba(148, 27, 0, 1) ; font-size: 8px !Important; font-weight:700">Response is delayed by 00:${
             data.TimeToStartAnswer
           }</div>
           
           ${
             data.answerDuration
               ? `<img style="margin-left: auto; height: 16px ;width:16px" src="${clock_icon}" /> ${data.answerDuration}`
               : ""
           }
        </div>
          <div style="padding-left: 10px;">
            <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 5px 0;">AI Interpretation</h2>
            <p>${data.interpretationText}</p>
          </div>
        </div>
        <hr />
      `;
        isFirstPage = false;
      } else {
        element.innerHTML = `
        <div style="display: flex;">
          <img src="${haire_logo}" style="width: 80px; height: 40px; margin-right: 20px;">
          <div style="text-align: right; flex-grow: 1;">
            <div style="font-family: helvetica; font-weight: bold; font-size: 16px;">${fullName} | ${
          demoJobsInfo.job_profile
        }</div>
            <div style="font-family: helvetica; font-weight: normal; font-size: 14px;">${timestamp}</div>
          </div>
        </div>
        <div>
          <div style="padding: 0;">
            <div style="font-size: 12px; font-weight: bold; color: #333; margin-bottom: 0;">Interview Transcript Report</div>
            <div style="font-size: 18px; color: #000; font-weight: bold; margin-bottom: 0;">${
              demoJobsInfo.job_profile
            }</div>
            <div style="font-size: 14px; color: #000; display: flex; align-items: center;">
              <div style="margin-right: 10px; color: #000;">⏱ ${interviewDuration}</div>
              <div style="color: #000;">📋 ${questionCount} Questions</div>
            </div>
            <hr style="margin-top: 10px; border: 0; border-top: 5px solid #1865C1;" />
          </div>
          <div style=" padding-left: 10px;">
            <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${
              data.question
            }</h2>
            <p>${data.questionText}</p>
          </div>
          <div style=" padding-left: 10px;">
      <h2 style="font-size: 16px; color: #0085FF; margin: 0 0 5px 0;">${
        data.answer
      } | ${
          data.answerDuration
            ? `<img src="${clock_icon}" /> ${data.answerDuration}`
            : ""
        }</h2>            <p>${data.answerText}</p>
          </div>
          <div style="border-left: 2px solid #08BCD0; padding-left: 10px;">
            <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 5px 0;">AI Interpretation</h2>
            <p>${data.interpretationText}</p>
          </div>
        </div>
        <hr />
      `;
        isFirstPage = false;
      }
    } else {
      if (data.TimeToStartAnswer > 15) {
        element.innerHTML = `
        <div style=" padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${
            data.question
          }</h2>
          <p>${data.questionText}</p>
        </div>
        <div style="padding-left: 10px; margin-top: 10px; display:flex; flex-direction: column; gap : 10px; align-items: center; >
          <div display:flex;flex-direction: row;font-size: 16px; color: #0085FF; margin: 0 0 8px 0; font-weight: bold;>
            <h2 style="font-size: 16px; color: #0085FF; margin: 0 0 8px 0; font-weight: bold;">${
              data.answer
            }</h2> 
           <div style="width:fit-content; background-color: rgba(148, 27, 0, 0.1);border-radius:3px ; padding: 2px 10px;  color:rgba(148, 27, 0, 1) ; font-size: 8px !Important; font-weight:700">Response is delayed by 00:${
             data.TimeToStartAnswer
           }</div>
           
           ${
             data.answerDuration
               ? `<img style="margin-left: auto; height: 16px ;width:16px" src="${clock_icon}" /> ${data.answerDuration}`
               : ""
           }
        </div>

          <p style="padding-left: 10px;">${data.answerText}</p>
        </div>
        <div style="border-left: 2px solid #08BCD0; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 8px 0;">AI Interpretation</h2>
          <p>${data.interpretationText}</p>
        </div>
        <hr />
      `;
      } else {
        element.innerHTML = `
        <div style=" padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${
            data.question
          }</h2>
          <p>${data.questionText}</p>
        </div>
        <div style=" padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #0085FF; margin: 0 0 8px 0;">${
            data.answer
          } | ${
          data.answerDuration
            ? `<img src="${clock_icon}" /> ${data.answerDuration}`
            : ""
        }</h2>
          <p>${data.answerText}</p>
        </div>
        <div style="border-left: 2px solid #08BCD0; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 8px 0;">AI Interpretation</h2>
          <p>${data.interpretationText}</p>
        </div>
        <hr />
      `;
      }
    }

    container.appendChild(element);
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const imgHeight = (canvas.height * 190) / canvas.width;

    if (yOffset + imgHeight > pdf.internal.pageSize.getHeight() - 20) {
      // Consider space for footer
      pdf.addPage();
      yOffset = 10; // Reset y-offset for the new page
    }

    pdf.addImage(imgData, "PNG", 10, yOffset, 190, imgHeight);
    yOffset += imgHeight + 10; // Increment y-offset for the next content, add consistent space
  }

  if (demoJobsInfoList?.interview?.tab_switch_count >= 3) {
    const terminationElement = document.createElement("div");
    terminationElement.innerHTML = `
      <div style="padding: 20px; margin-top: 20px;background-color: hsla(0, 100%, 43%, 0.08);border-radius:6px">
        <h4 style="color: #DC0000; margin: 0;font-size:14px;margin-bottom:10px">Interview Terminated</h4>
        <p style="margin: 0;font-size:12px"">The candidate's interview was terminated due to frequently being distracted by switching tabs, even after several reminders.</p>
      </div>
    `;
    container.appendChild(terminationElement);
    const terminationCanvas = await html2canvas(terminationElement, {
      scale: 2,
    });
    const terminationImgData = terminationCanvas.toDataURL("image/png");
    const terminationImgHeight =
      (terminationCanvas.height * 190) / terminationCanvas.width;

    if (
      yOffset + terminationImgHeight >
      pdf.internal.pageSize.getHeight() - 20
    ) {
      pdf.addPage();
      yOffset = 10;
    }

    pdf.addImage(
      terminationImgData,
      "PNG",
      10,
      yOffset,
      190,
      terminationImgHeight
    );
  }

  const addFooter = () => {
    const pageCount = pdf.internal.getNumberOfPages(); // Get total number of pages
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.setTextColor(150);
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.text(
        "Page  " + i + " of " + pageCount,
        pdf.internal.pageSize.getWidth() - 20,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }
  };

  addWatermarkToAllPages(pdf);
  addFooter();
  pdf.save(`${fullName}-${demoJobsInfoList?.title}-Transcript.pdf`);
  document.body.removeChild(container); // Clean up
};
