import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";

import { star_bg , blue_arow} from "../../../assets/images/images";
import { useNavigate } from "react-router-dom";

const NewBanner = () => {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/try-haire");
  }

  return (
    <>
      <section className='new_landign_ai_banner_sec'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='new_landign_ai_banner_sec_main'>
                        <div className='new_landign_ai_banner_sec_main_left'>
                            <h2 className='wow animate__zoomIn' data-wow-duration="2s">Screens, <div className='interview_text'><p>interviews</p><img src={star_bg} alt="star" /></div><br/> shortlists, and assesses applicants for you <span className="in_text">in minutes!</span></h2>
                            <ul className='hm_list_sec wow animate__zoomIn' data-wow-duration="2.5s">
                              <li>Advanced AI Powered ATS</li>
                              <li>AI Driven Job Portal</li>
                            </ul>
                            <button
                              className="try-button try-haire-btn wow animate__zoomIn"
                              data-wow-duration="3.5s"
                              onClick={handleBookDemoClick}
                            >
                              Book Demo
                              <div className="round-arow"><img
                                className="try-haire-btn-sub-new"
                                src={blue_arow}
                                alt="round arrow"
                            
                              /></div>
                            </button>
                        </div>
                        <div className='new_landign_ai_banner_sec_main_right'>
                            <div className="calendly-inline-widget" data-url="https://calendly.com/d/crc3-gfw-w4g/ai-driven-smart-hiring-powered-by-haire-ai" style={{Width: '400px', height: '550px'}}></div>
                        </div>
                    </div>
                    <div
                      class="scroll-down wow animate__zoomIn"
                      data-wow-duration="4s"
                    >
                      <span class="scroll-dot"></span>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default NewBanner
