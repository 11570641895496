import { Dispatch } from "redux";
// @ts-ignore
import instance from "../../api/instance";
import {
  setJobseekerId,
  setTempResume,
  setUser,
} from "../reducers/authReducer";
// @ts-ignore
import { getCurrentFormattedDateTime } from "../../utils/utils";
import { setJobLoading, setJobError } from "../reducers/jobReducer";
import { toast } from "react-toastify";

export const createJobSeeker = (
  token: string,
  resume: string,
  languages: string[] | string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const formData = new FormData();
    formData.append("resume", resume);

    // Join the languages array into a single string if it's an array
    const languagesStr = Array.isArray(languages)
      ? languages.join(",")
      : languages;
    formData.append("languages", languagesStr);

    try {
      const response = await instance.post("/jobseekers/create/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setUser(response.data.data));
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // Get the message from the error response
        const errorMessage =
          error.response.data?.message || "Failed to upload resume";
        toast.error(`Error: ${errorMessage}`);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      dispatch(setJobError("Failed to create job details: " + error.message));
      throw new Error(error?.message);
    } finally {
      dispatch(setJobLoading(false));
    }
  };
};

export const getPdfToJSON = (
  token: string,
  resume: string,
  languages: string[] | string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const formData = new FormData();
    formData.append("resume", resume);

    // Join the languages array into a single string if it's an array
    const languagesStr = Array.isArray(languages)
      ? languages.join(",")
      : languages;
    formData.append("languages", languagesStr);

    try {
      const response = await instance.put(
        "/jobseekers/create-resume-json/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        response.data.data.resume = resume;
        dispatch(setTempResume(response.data.data));
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // Get the message from the error response
        const errorMessage =
          error.response.data?.message || "Failed to upload resume";
        toast.error(`${errorMessage}`);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      dispatch(setJobError("Failed to create job details: " + error.message));
      throw new Error(error?.message);
    } finally {
      dispatch(setJobLoading(false));
    }
  };
};

export const updateJobSeeker = (
  token: string,
  userId: number,
  resume?: string,
  resumeJson?: JSON,
  profile_pic?: string,
  formDetails?: Record<string, any>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    const updatedFormData = new FormData();
    if (profile_pic) {
      updatedFormData.append("profile_pic", profile_pic);
    }
    if (resumeJson) {
      updatedFormData.append("resume_json", JSON.stringify(resumeJson));
    }
    if (resume) {
      updatedFormData.append("resume", resume);
    }
    if (formDetails) {
      Object.keys(formDetails).forEach((key) => {
        updatedFormData.append(key, formDetails[key]);
      });
    }

    try {
      const response = await instance.put(
        `/jobseekers/update/${userId}/`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setUser(response.data.data));
        dispatch(setJobseekerId(response.data.data.id));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // Get the message from the error response
        const errorMessage =
          error.response.data?.message || "Failed to upload resume";
        toast.error(`Error: ${errorMessage}`);
        dispatch(setJobError(`Failed to update job details: ${errorMessage}`));
      } else {
        toast.error("An unexpected error occurred. Please try again.");
        dispatch(setJobError("Failed to update job details: " + error.message));
      }
    } finally {
      dispatch(setJobLoading(false));
    }
  };
};


export const updateEnhancedResume = (
  token: string,
  userId: number,
  resumeJson?: JSON
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));
    const updatedFormData = new FormData();
    // console.log("resume_json", resumeJson);
    if (resumeJson) {
      updatedFormData.append("resume_json", JSON.stringify(resumeJson));
    }

    try {
      const response = await instance.put(
        `/jobseekers/update/${userId}/`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // console.log("response.data.data", response.data.data);
        dispatch(setUser(response.data.data));
        dispatch(setJobseekerId(response.data.data.id));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // Get the message from the error response
        const errorMessage = error.response.data?.message || "Failed to upload resume";
        toast.error(`Error: ${errorMessage}`);
        dispatch(setJobError(`Failed to update job details: ${errorMessage}`));
      } else {
        toast.error("An unexpected error occurred. Please try again.");
        dispatch(setJobError("Failed to update job details: " + error.message));
      }
    } finally {
      dispatch(setJobLoading(false));
    }
  };
};


        
export const fetchJobSeekerDetails =
  (token: string, jobSeekerId: number) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.get(
        `/jobseekers/jobseeker-details/${jobSeekerId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setUser(response.data.data)); // updateJobDetails action to update Redux state with job details
        dispatch(setJobLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(setJobError("Failed to fetch job details: " + error.message)); // setJobError action for error handling
    } finally {
      dispatch(setJobLoading(false));
    }
  };

export const createInterview =
  (
    jobId: number,
    token: string,
    status: number,
    selectedDate?: string,
    selectedTime?: string,
    timezone?: string
  ) =>
    async (dispatch: Dispatch) => {
      dispatch(setJobLoading(true));

      // Use the selectedDate and selectedTime if provided, otherwise get the current formatted date and time
      const { date: formattedDate, time: formattedTime } =
        selectedDate && selectedTime
          ? { date: selectedDate, time: selectedTime }
          : getCurrentFormattedDateTime();

      const body = {
        date: formattedDate,
        start_time: formattedTime,
        status: status,
        job: jobId,
        timezone: timezone,
      };

      try {
        const response = await instance.post(
          `/interviews/create-interview/`,
          body,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (status === 2) {
          // Assuming the interview ID is returned in the response data under 'id'
          const interviewId = response.data.data.id;
          const authResponse = await instance.get(
            `/interviews/google-auth/?interview_id=${interviewId}`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
              maxRedirects: 0,
            }
          );
          window.location.href = authResponse.headers.location;
        }

        dispatch(setJobLoading(false));
        return response.data;
      } catch (error) {
        console.error("Error creating interview:", error);
        dispatch(setJobLoading(false));
        throw error; // Rethrow the error to handle it in the component
      }
    };

export const trackTabSwitch =
  (interviewId: number, token: string) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.post(
        `/interviews/increment-tab-switch/${interviewId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      dispatch(setJobLoading(false));
      return response.data;
    } catch (error) {
      console.error("Error tracking tab switch:", error);
      dispatch(setJobLoading(false));
      throw error;
    }
  };

export const trackTryHaireTabSwitch =
  (interviewId: number, token: string) => async (dispatch: Dispatch) => {
    dispatch(setJobLoading(true));

    try {
      const response = await instance.post(
        `demo/increment-tab-switch-count-for-demo-interview/${interviewId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      dispatch(setJobLoading(false));
      return response.data;
    } catch (error) {
      console.error("Error tracking tab switch:", error);
      dispatch(setJobLoading(false));
      throw error;
    }
  };

export const trackTryHaireIdleTime =
  (interviewId: number, token: string) => async (dispatch: Dispatch) => {
    try {
      const response = await instance.post(
        `demo/update-response-delayed-flag/${interviewId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error tracking idle time:", error);

      throw error;
    }
  };

export const trackHaireIdleTime =
  (interviewId: number, token: string) => async (dispatch: Dispatch) => {
    try {
      const response = await instance.post(
        `interviews/update-response-delayed-in-interview/${interviewId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error tracking idle time:", error);

      throw error;
    }
  };
