import { Progress } from "antd";
import { useEffect, useRef, useState } from "react";
import { Microphone } from "../../../assets/images/images";
import TranscriptionTest from "../../CustomComponents/microphone";
import LoaderApply from "../Loader/LoaderApply";
import "./InterviewModalComponents.css";

const InterviewModalComponant = ({ onDone, mediaStream }) => {
  //Speaker tests
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(null);
  const [audio, setAudio] = useState(null);
  const [showPermissionRequest, setShowPermissionRequest] = useState(true);
  const [showMicrophoneTest, setShowMicrophoneTest] = useState(false);

  //Microphone tests
  const [micProgress, setMicProgress] = useState(0);
  const [isTesting, setIsTesting] = useState(false);
  const [recording, setRecording] = useState(null);
  let animationFrameId;
  const mediaRecorderRef = useRef(null);
  const [hasTestedOnce, setHasTestedOnce] = useState(false);

  //Net speed tests
  const [speed, setSpeed] = useState(null);
  const [testing, setTesting] = useState(false);
  const [testMessage, setTestMessage] = useState("");
  const [netProgress, setNetProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState("normal");
  const [showSpeedTest, setShowSpeedTest] = useState(false);

  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);

  ///confirmation modal
  const [finalMessage, setFinalMessage] = useState(false);

  //////////speaker logic///////////
  const twoColors = {
    "0%": "rgba(24, 101, 193, 1)",
  };
  const doneButtonClass = hasPlayedOnce
    ? "blue_bg_btn"
    : "blue_bg_btn disabled";
  const handleDone = () => {
    setShowMicrophoneTest(false);
    setShowSpeedTest(true);
  };

  useEffect(() => {
    const audioObj = new Audio(`https://haire.s3.ap-south-1.amazonaws.com/development/test.wav`);
    audioObj.loop = true;
    audioObj.addEventListener("ended", () => {
      audioObj.currentTime = 0;
      audioObj.play();
    });
    setAudio(audioObj);
    return () => {
      if(audio) {
        audio.pause()
      }
      if (audioObj) {
        audioObj.pause();
        audioObj.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    let intervalId;

    if (isPlaying) {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress =
            oldProgress +
            (Math.random() > 0.5 ? 1 : -1) * (5 + Math.random() * 10);
          return Math.min(100, Math.max(0, newProgress));
        });
      }, 200);
    } else {
      clearInterval(intervalId);
      setProgress(0);
    }

    return () => clearInterval(intervalId);
  }, [isPlaying]);

  const startSpeakerTest = () => {
    if (!audio) {
      return;
    }
    if (!isPlaying) {
      setHasPlayedOnce(true); // Update the state when the audio is played for the first time
    }
    audio.paused ? audio.play() : audio.pause();
    setIsPlaying(!isPlaying);
  };
  const handleUserSpeakerConfirmation = () => {
    // Stop and reset the speaker test
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    setIsPlaying(false);
    setUserConfirmed(false); // Assuming this state is meant to confirm the speaker test

    // Now show the microphone test section
    setShowMicrophoneTest(true);
  };

  const handlePermission = async () => {
    try {
      setShowPermissionRequest(false);
      setUserConfirmed(true); // Hide the permission modal
    } catch (error) {
      console.error("Permission denied or error occurred:", error);
    }
  };

  useEffect(() => {
    if (isTesting) {
      mediaStream
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.start();

          const audioChunks = [];
          mediaRecorderRef.current.addEventListener(
            "dataavailable",
            (event) => {
              audioChunks.push(event.data);
            }
          );

          mediaRecorderRef.current.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            setRecording(audioUrl);
          });

          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);
          microphone.connect(analyser);

          const dataArray = new Uint8Array(analyser.frequencyBinCount);
          const updateMicProgress = () => {
            analyser.getByteFrequencyData(dataArray);
            let sum = 0;
            for (let i = 0; i < dataArray.length; i++) {
              sum += dataArray[i];
            }
            setMicProgress(sum / dataArray.length);
            if (isTesting) requestAnimationFrame(updateMicProgress);
          };

          updateMicProgress();
        })
        .catch((error) => {
          console.error("Error accessing the microphone", error);
        });

      const simulateProgress = () => {
        setMicProgress((oldProgress) => {
          const change = Math.random() * 10 - 5; // Random fluctuation
          let newProgress = oldProgress + change;
          if (newProgress > 100) newProgress = 100;
          if (newProgress < 0) newProgress = 0;
          return newProgress;
        });

        animationFrameId = requestAnimationFrame(simulateProgress);
      };

      animationFrameId = requestAnimationFrame(simulateProgress);
    } else {
      cancelAnimationFrame(animationFrameId);
    }

    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      cancelAnimationFrame(animationFrameId);
      setIsTesting(false);
      setMicProgress(0);
    };
  }, [isTesting]);

  ////////Net Speed Test////////////////////////////////
  const testInternetSpeed = async () => {
    const fileSizeInBytes = 10000000;
    const fileUrl = `https://eu.httpbin.org/stream-bytes/${fileSizeInBytes}`; // URL of a large file

    setTesting(true);
    setSpeed(null);
    setTestMessage("");
    setNetProgress(0);

    if (!hasTestedOnce) {
      setHasTestedOnce(true);
    }

    try {
      const startTime = new Date().getTime();

      const response = await fetch(fileUrl, {
        method: "HEAD", // HEAD request to avoid downloading the file content
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const endTime = new Date().getTime();
      const durationInSeconds = (endTime - startTime) / 1000;
      const bitsLoaded = fileSizeInBytes * 8;
      const speedBps = bitsLoaded / durationInSeconds;
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);

      setSpeed(speedMbps);

      let quality = "Poor";
      let percent = 30; // Default to 30% for poor connection
      if (speedMbps > 50) {
        quality = "Fair";
        percent = 60; // Set to 60% for an average connection
      }
      if (speedMbps > 150) {
        quality = "Good";
        percent = 100; // Set to 100% for a good connection
      }

      setTestMessage(`Internet Connectivity: ${speedMbps} Mbps - ${quality}`);
      setNetProgress(percent);
      setProgressStatus(quality === "Poor" ? "exception" : "success");

      setTesting(false);
    } catch (error) {
      console.error("Error:", error);
      setSpeed("Speed test failed");
    }

    setTesting(false);
  };
  const progressColor = progressStatus === "exception" ? "#ff4d4f" : "#108ee9";

  const handleCloseSpeed = () => {
    setShowSpeedTest(false);

    setFinalMessage(true);
  };


  const handleClose = () => {
    if (onDone) {
      onDone();
    }
  };
  return (
    <>
      {showPermissionRequest && (
        <div className="InterviewModal_main">
          <div className="InterviewModal_main_inner">
            <div>
              <img src={Microphone} alt="" />
            </div>
            <h2>
              Kindly grant permission to access your microphone and camera to
              start the interview.
            </h2>
          </div>
          <div>
            <button className="blue_bg_btn" onClick={handlePermission}>
              Allow microphone and camera
            </button>
          </div>
        </div>
      )}

      {userConfirmed && (
        <div className="InterviewModal_main">
          <div className="InterviewModal_main_inner">
            <div className="test_speaker">
              <h2>Test Your Speaker</h2>
            </div>
            <div className="output_level">
              <p>Output Level</p>
              <div className="custom-progress">
                <Progress
                  percent={progress}
                  strokeColor={twoColors}
                  showInfo={false}
                />
              </div>
            </div>
          </div>
          <div className="space-bt">
            <button className="clear_bg_btn" onClick={startSpeakerTest}>
              {isPlaying ? "Pause" : "Play"}
            </button>
            <button
              className={doneButtonClass}
              onClick={handleUserSpeakerConfirmation}
              disabled={!hasPlayedOnce}
            >
              Done
            </button>
          </div>
        </div>
      )}
      {showMicrophoneTest && <TranscriptionTest onDone={handleDone} />}
      {showSpeedTest && (
        <div className="InterviewModal_main">
          <div className="InterviewModal_main_inner">
            <div>
              <div className="test_speaker">
                <h2>Test Your Internet Connectivity</h2>
              </div>

              {testing ? (
                <div className="loader-container">
                  <LoaderApply color="#1865c1" />
                </div>
              ) : (
                speed && (
                  <>
                    <p className="internet">
                      Internet Connectivity :{" "}
                      <span
                        className={`speed ${
                          speed < 10 ? "speed-red" : "speed-green"
                        }`}
                      >
                        {speed} Mbps
                      </span>
                    </p>
                    <p className="internet_speed">
                      {speed < 10
                        ? "Poor internet connection detected. Please check your network connection."
                        : "Your internet connection is strong and stable."}
                    </p>
                  </>
                )
              )}
            </div>
            <div className="actions">
              <button
                onClick={testInternetSpeed}
                disabled={testing}
                className="try-again clear_bg_btn"
              >
                {hasTestedOnce ? "Try Again" : "Test Speed"}
              </button>
              <button
                onClick={handleCloseSpeed}
                className={`done blue_bg_btn ${
                  !hasTestedOnce ? "disabled" : ""
                }`}
                disabled={!hasTestedOnce}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {finalMessage && (
        <div className="InterviewModal_main">
          <div className="InterviewModal_main_inner">
            <div className="test_speaker center-text">
              <h2>Your Device is working properly</h2>
            </div>
            <div className="output_level">
              <div className="check">
                <p>
                  Speaker : <span>Tested</span>
                  <i
                    className="fas fa-check"
                    style={{ color: "#2ebd2e", marginLeft: "8px" }}
                  ></i>
                </p>
                <p>
                  Microphone : <span>Tested</span>
                  <i
                    className="fas fa-check"
                    style={{ color: "#2ebd2e", marginLeft: "8px" }}
                  ></i>
                </p>
                <p>
                  Internet Connection : <span>Tested</span>
                  <i
                    className="fas fa-check"
                    style={{ color: "#2ebd2e", marginLeft: "8px" }}
                  ></i>
                </p>
              </div>
            </div>
          </div>
          <div className="space-bt">
            <button className="blue_bg_btn" onClick={handleClose}>
              Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default InterviewModalComponant;
