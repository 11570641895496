import { create } from 'zustand';
// @ts-ignore
import instance from "../api/instance";
import { detectBrowser } from './screenRecordStore';

// 5 MB chunk size
const MIN_CHUNK_SIZE = 5 * 1024 * 1024;

export const screenRecordingSessionStore = create((set) => ({
    recording: false,
    uploadId: null,
    partNumber: 1,
    accumulatedChunk: null, // Store for accumulating chunks
    uploading: false, // Lock mechanism to prevent multiple uploads

    // Function to start screen recording (initiate multipart upload)
    startRecordingSession: async (token: string, interviewId: string) => {
        try {
            const browser = detectBrowser();
            const formData = new FormData();
            formData.append('file_type', browser === "firefox" ? "webm" : "mp4");

            const response = await instance.post(`/interviews/initiate-multipart-upload/${interviewId}/`, formData, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            const uploadId = response.data.upload_id;
            set({ recording: true, uploadId, partNumber: 1, accumulatedChunk: new Blob(), uploading: false });
        } catch (error) {
            console.error('Error starting recording session:', error);
        }
    },

    // Function to upload video chunks (multipart upload)
    uploadChunk: async (token: string, chunk: any, interviewId: string) => {
        // @ts-ignore
        const { uploadId, partNumber, accumulatedChunk, uploading } = screenRecordingSessionStore.getState();

        // Prevent multiple uploads by checking the `uploading` state
        if (uploading) return; // Exit if already uploading

        try {
            let newAccumulatedChunk = new Blob([accumulatedChunk, chunk], { type: chunk.type });

            if (newAccumulatedChunk.size >= MIN_CHUNK_SIZE) {
                // Lock the upload process to prevent multiple calls
                set({ uploading: true });

                // Send the chunk when it reaches or exceeds the minimum size (5 MB)
                const browser = detectBrowser();
                const formData = new FormData();
                formData.append('upload_id', uploadId);
                formData.append('part_number', partNumber);
                formData.append('file', newAccumulatedChunk);
                formData.append('file_type', browser === "firefox" ? "webm" : "mp4");

                // Upload the part and get the ETag
                await instance.post(`/interviews/upload-part/${interviewId}/`, formData, {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Store the ETag and part number, reset accumulated chunk
                set({
                    partNumber: partNumber + 1,
                    accumulatedChunk: new Blob(), // Reset accumulated chunk
                    uploading: false, // Unlock upload
                });
            } else {
                // Keep accumulating the chunks if the total is still less than 5 MB
                set({ accumulatedChunk: newAccumulatedChunk });
            }
        } catch (error) {
            console.error('Error uploading chunk:', error);
            // Reset the uploading state even if there's an error
            set({ uploading: false });
        }
    },

    // Function to stop recording and complete multipart upload
    // Update the stopRecordingSession function to ensure all chunks are uploaded before completion
    stopRecordingSession: async (token: string, interviewId: string) => {
        // @ts-ignore
        const { uploadId, accumulatedChunk, partNumber, uploading } = screenRecordingSessionStore.getState();


        if (uploading) return; // Prevent stopping while uploading

        try {
            // Check if there's any remaining accumulated chunk to upload
            if (accumulatedChunk.size > 0) {
                const browser = detectBrowser();
                const formData = new FormData();
                formData.append('upload_id', uploadId);
                formData.append('part_number', partNumber);
                formData.append('file', accumulatedChunk);
                formData.append('file_type', browser === "firefox" ? "webm" : "mp4");

                // Upload the remaining chunk
                await instance.post(`/interviews/upload-part/${interviewId}/`, formData, {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Increment part number after uploading the last chunk
                set({
                    partNumber: partNumber + 1,
                    accumulatedChunk: new Blob(), // Reset accumulated chunk
                });
            }

            // Now, mark the multipart upload as complete
            const browser = detectBrowser();
            const formDataComplete = new FormData();
            formDataComplete.append('upload_id', uploadId);
            formDataComplete.append('file_type', browser === "firefox" ? "webm" : "mp4");

            await instance.post(`/interviews/complete-multipart-upload/${interviewId}/`, formDataComplete, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });

            // Reset state after completing the upload
            set({ recording: false, uploadId: null, partNumber: 1, accumulatedChunk: null, uploading: false });
        } catch (error) {
            console.error('Error completing multipart upload:', error);
        }
    },
}));

export default screenRecordingSessionStore;
