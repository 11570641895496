import {
  ClockCircleOutlined,
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import MuxVideo from "@mux/mux-video-react";
import { Button, Input, Tag, Collapse, Col, Tooltip } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers";
import { ThreeCircles } from "react-loader-spinner";
import "videojs-markers/dist/videojs.markers.css";
import {
  Search_grey,
  download_icon,
  full,
} from "../../../../assets/images/images";
import {
  convertTimeToSeconds,
  formatDurationTime,
  getVideoType,
  parseTimeToSeconds,
} from "../../../../utils/utils";
import { detectBrowser } from "../../../../zustand/screenRecordStore";
import "./results.css";
import { assesmentPdf } from "../../../../utils/pdfFiles/assesmentPdf";
import { date } from "yup";
import { generateDemoPdf } from "../../../../utils/pdfFiles/demoTranscriptPdf";

const CustomExpandIcon = ({ isActive }) => {
  return isActive ? <UpOutlined /> : <DownOutlined />;
};
const Transcript = () => {
  const [activeKey, setActiveKey] = useState(null); // State to track active panel
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTranscript, setFilteredTranscript] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(-1);
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const highlightRefs = useRef([]);
  const browser = detectBrowser();
  const applicant = useSelector((state) => state?.job?.applicationResult);
  const transcriptVideoLink = applicant?.interview_details?.video_link;
  const transcriptVideoType = getVideoType(transcriptVideoLink);

  const jobProfileDetails = {
    full_name: applicant?.user_full_name,
    job_profile: applicant?.job_title,
  };

  const jobInterviewDetails = {
    title: applicant?.job_title,
    interview: {
      started_at: applicant?.interview_details?.started_at,
      ended_at: applicant?.interview_details?.ended_at,
      date: applicant?.interview_details?.date,
    },
  };

  useEffect(() => {
    if (applicant && applicant.transcript) {
      setFilteredTranscript([...applicant.transcript]);
    }
    setCurrentTranscriptIndex(0);
  }, [applicant?.transcript]);

  const scrollToHighlight = (index) => {
    const highlightEl = highlightRefs.current[index];
    if (highlightEl) {
      const { top, bottom } = highlightEl.getBoundingClientRect();
      const isInView = top >= 0 && bottom <= window.innerHeight;
      if (!isInView) {
        highlightEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const handleDownloadPdf = async () => {
    if (applicant.transcript && jobProfileDetails && jobInterviewDetails) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await generateDemoPdf(
        applicant.transcript,
        jobProfileDetails,
        jobInterviewDetails
      ); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected transcript or applicant is undefined");
    }
  };

  useEffect(() => {
    if (applicant?.transcript && videoRef.current) {
      // Initialize video player
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        width: 800,
        height: 450,
        playbackRates: [0.5, 1, 1.5, 2],
        fluid: true,
        controlBar: {
          volumePanel: { inline: false },
          pictureInPictureToggle: true,
          fullscreenToggle: true,
        },
      });

      // Add markers to the video
      playerRef.current.markers({
        markerStyle: {
          width: "2px",
          backgroundColor: "red",
        },
        markers: applicant.transcript.map((item) => ({
          time: convertTimeToSeconds(item.question_asked_at),
          text: item.ai_question,
        })),
        onMarkerClick: (marker) => playerRef.current.currentTime(marker.time),
      });

      // Time update handler for precise transcript synchronization
      const timeUpdateHandler = () => {
        if (!playerRef.current) return;

        const currentTime = playerRef.current.currentTime();
        const currentIndex = applicant.transcript.findIndex((item, index) => {
          const currentTimeInSeconds = convertTimeToSeconds(
            item.question_asked_at
          );
          const nextItem = applicant.transcript[index + 1];
          const nextTimeInSeconds = nextItem
            ? convertTimeToSeconds(nextItem.question_asked_at)
            : Infinity;

          return (
            currentTime >= currentTimeInSeconds &&
            currentTime < nextTimeInSeconds
          );
        });

        if (currentIndex !== -1 && currentIndex !== currentTranscriptIndex) {
          setCurrentTranscriptIndex(currentIndex);
          // scrollToHighlight(currentIndex);
        }
      };

      // // Add time update event listener
      playerRef.current.on("timeupdate", timeUpdateHandler);

      // Add listener for route changes
      const handleRouteChange = () => {
        if (playerRef.current) {
          playerRef.current.pause();
        }
      };

      window.addEventListener("routeChanged", handleRouteChange);

      // Cleanup: Stop the video and dispose of the player when:
      // 1. Component unmounts
      // 2. Route changes
      // 3. Tab changes
      return () => {
        window.removeEventListener("routeChanged", handleRouteChange);
        if (playerRef.current) {
          // playerRef.current.off("timeupdate", timeUpdateHandler);
          playerRef.current.pause();
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }
  }, [applicant?.transcript]);

  // Calculate total matches for search term
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setHighlightIndex(0);
    if (applicant?.transcript) {
      const filtered = applicant.transcript.filter(
        (item) =>
          item?.candidate_answer
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item?.ai_question.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredTranscript(filtered);
    }
  };

  const handleTranscriptClick = (question_asked_at) => {
    const timeInSeconds = convertTimeToSeconds(question_asked_at);
    if (playerRef.current) {
      if (browser === "firefox" && transcriptVideoType === "webm") {
        playerRef.current.currentTime = timeInSeconds+10;
        playerRef.current.play(); // Add play() for MuxVideo
      } else {
        playerRef.current.currentTime(timeInSeconds+10);
        playerRef.current.play(); // Add play() for VideoJS player
      }
    } else {
      console.error("Video player is not initialized properly.");
    }
  };

  const totalMatches = useMemo(() => {
    if (!searchTerm) return 0;

    let count = 0;
    filteredTranscript.forEach((item) => {
      const questionMatches = (
        item.ai_question.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      const answerMatches = (
        item.candidate_answer.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      const interpretationMatches = (
        item.candidate_answer.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      count += questionMatches + answerMatches +interpretationMatches;
    });

    return count;
  }, [searchTerm, filteredTranscript]);

  const handleMarkerClick = (time) => {
    if (playerRef.current) {
      playerRef.current.currentTime = time;
    }
  };

  const renderMarkers = () => {
    const duration = playerRef.current?.duration || 1; // Prevent division by zero
    return (
      applicant?.transcript?.map((item, index) => {
        const time = convertTimeToSeconds(item.question_asked_at);
        return (
          <div
            key={index}
            className="marker"
            style={{
              left: `${(time / duration) * 100}%`,
            }}
            onClick={() => handleMarkerClick(time)}
          ></div>
        );
      }) || []
    );
  };

  const handleTimeUpdate = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime;
      const currentIndex = filteredTranscript.findIndex((item, index) => {
        const current = convertTimeToSeconds(item.question_asked_at);
        const next = filteredTranscript[index + 1]
          ? convertTimeToSeconds(
              filteredTranscript[index + 1].question_asked_at
            )
          : Infinity;
        return currentTime >= current && currentTime < next;
      });
      if (currentIndex !== currentTranscriptIndex) {
        setCurrentTranscriptIndex(currentIndex);
        scrollToHighlight(currentIndex);
      }
    }
  };

  const renderVideoComponent = () => {
    return browser === "firefox" && transcriptVideoType === "webm" ? (
      <div className="mux-video-wrapper">
        <MuxVideo
          ref={(el) => {
            playerRef.current = el; // Assign the native video element to playerRef
          }}
          style={{ height: "100%", maxWidth: "100%" }}
          src={transcriptVideoLink}
          controls
          preload="auto"
        />
        {/* <div className="marker-container">{renderMarkers()}</div> */}
      </div>
    ) : (
      <div data-vjs-player>
        <video
          ref={videoRef}
          src={transcriptVideoLink}
          className="video-js vjs-default-skin"
          controls
          preload="auto"
        ></video>
      </div>
    );
  };

  const handlePanelChange = async (key, question_asked_at) => {
    await handleTranscriptClick(question_asked_at);
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  useEffect(() => {
    const videoElement = document.querySelector("mux-video");

    if (browser === "firefox" && transcriptVideoType === "webm") {
      playerRef.current = videoElement;
    }

    const handleMetadataLoaded = () => {
      renderMarkers(); // Re-render markers after metadata is loaded
    };

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      // videoElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
        // videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [applicant?.transcript]);

  return (
    <div className="Transcript_main Transcript_main_wrapper">
      <Col span={16}>
        <div className="video-player-container">
          <div className="video-player-section">{renderVideoComponent()}</div>
        </div>
      </Col>
      <Col span={7}>
        {applicant?.transcript?.length > 0 && (
          <section className="Transcript_main_action1">
            <div className="Transcript_main_search_section">
              <div className="Transcript_main_title">
                <p>Interview Transcript</p>
                <button
                  className="transcript_download_button download_btn"
                  onClick={() => handleDownloadPdf()}
                >
                  <span>Download</span>
                  <div className="icon-container">
                    {" "}
                    {/* Add container for icons */}
                    {loadingPdf ? (
                      <ThreeCircles color="#1865c1" height={20} width={20} />
                    ) : (
                      <img src={download_icon} alt="" />
                    )}
                  </div>
                </button>
              </div>
              <div className="Search_jobs">
                <img src={Search_grey} alt="search_job" />
                <input
                  type="search"
                  className="search_input_transcript"
                  placeholder="Search transcript..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
                {filteredTranscript.length > 0 ? (
                  totalMatches > 0 ? (
                    <div className="word-count">
                      {`Found : ${totalMatches} results`}
                    </div>
                  ) : null
                ) : (
                  <div className="word-count">{`No Results Found`}</div>
                )}
              </div>
            </div>
            <div>
              <div className="Transcript_main_action min-vd">
                {filteredTranscript.length > 0 &&
                  filteredTranscript.map((transcript, index) => (
                    <Collapse
                      accordion
                      activeKey={activeKey}
                      ref={(el) => (highlightRefs.current[index] = el)}
                      className={`collaps_comp ${
                        currentTranscriptIndex === index
                          ? "highlighted-section"
                          : ""
                      }`}
                      onChange={() =>
                        handlePanelChange(
                          index.toString(),
                          transcript.question_asked_at
                        )
                      }
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <CustomExpandIcon isActive={isActive} />
                      )}
                      items={[
                        {
                          key: index,
                          label: (
                            <>
                              <p className="trans_question_main">
                                <strong>Question {index + 1} : Hairo AI</strong>{" "}
                                {(() => {
                                  // REPLACE THE ZERO WITH THE VARIABLE TO TOGGLE THE WARNING TAG
                                  if (0 > 15)
                                    return (
                                      <Tag className="custom_que_tag">
                                        Custom Question
                                      </Tag>
                                    );
                                })()}
                                <br />
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={transcript?.ai_question}
                                />
                              </p>

                              {(() => {
                                const delayInSeconds = parseTimeToSeconds(
                                  transcript?.time_to_start_answer
                                );
                                if (delayInSeconds > 15) {
                                  return (
                                    <Tooltip title="The candidate took longer than expected to respond to this question, indicating a possible delay in formulating their answer.">
                                      <Tag
                                        color="#941B001A"
                                        style={{
                                          color: "#941B00",
                                          fontSize: "9px",
                                          marginLeft: "0px",
                                          marginBottom: "0px",
                                          marginTop: "8px",
                                          marginRight: "4px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Response Delayed by 00:
                                        {delayInSeconds} sec
                                        {/* <ClockCircleOutlined /> */}
                                      </Tag>

                                      <InfoCircleOutlined
                                        style={{
                                          color: "rgba(33, 37, 41, 1)",
                                        }}
                                      />
                                    </Tooltip>
                                  );
                                }
                                return null;
                              })()}
                            </>
                          ),
                          children: (
                            <div>
                              <hr style={{ marginTop: "0px" }} />
                              <p className="trans_answer">
                                <strong className="strong_answer">
                                  <div className="candidate_ans_header">
                                    <div className="hearder_first">
                                      {applicant?.user_full_name}{" "}
                                    </div>
                                    {transcript?.answer_duration && (
                                      <>
                                        {" "}
                                        {/* <ClockCircleOutlined />{" "} */}
                                        {formatDurationTime(
                                          transcript?.answer_duration
                                        )}
                                      </>
                                    )}
                                  </div>
                                </strong>{" "}
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={transcript?.candidate_answer}
                                />
                              </p>
                              <hr />
                              <p className="trans_interpretation">
                                <strong>
                                  AI interpretation :
                                  <br />
                                </strong>{" "}
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={
                                    transcript?.ai_interpretation
                                  }
                                />
                              </p>
                            </div>
                          ),
                        },
                      ]}
                    />
                  ))}
                {applicant?.interview_details?.tab_switch_count >= 3 && (
                  <div className="terminated_section">
                    <h4 className="terminated">Interview Terminated</h4>
                    <p className="terminated_p">
                      The candidate's interview was terminated due to frequently
                      being distracted by switching tabs, even after several
                      reminders.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </Col>
    </div>
  );
};

export default Transcript;
