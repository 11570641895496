import React, { useEffect } from "react";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import "../Features/Features.css";
import HomeFooter from "../../components/HomeComponents/HomeFooter";
import { Link, useNavigate } from "react-router-dom";
import GetInTouch from "../../components/HomeComponents/GetInTouch";
import {
  ai_hiring,
  ai_interview,
  border_corner,
  main_gi,
} from "../../assets/images/images";
import { Helmet } from "react-helmet";
import HomeFooterNew from "../../components/HomeComponents/Footer";
import { WOW } from 'wowjs';
import 'animate.css/animate.min.css';
import { useSelector } from "react-redux";

const Features = () => {
  const subscriptions = useSelector((state) => state.subscriptions);
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };

  const ctaBtn = (
    <Link className="get_start_btn" onClick={handleClick}>
      {/* <img src="../../images/btn-bg.svg" className="btn_bg_img" />  */}
      Get Started
    </Link>
  );

  useEffect(() => {
    new WOW().init();
  }, []);

  const handleDemoClick = (event) => {
    event.preventDefault();
    window.open(subscriptions?.zoho_link, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>AI Interview Platform Features | Haire</title>
        <meta
          name="description"
          content="Discover Haire.ai's AI video interview platform features, automated hiring tools, and candidate assessment AI solutions."
        />
        <link rel="canonical" href="https://haire.ai/features" />
      </Helmet>
      
      {/* Navigation Section */}
      <HomeHeader />

      <section className="feature_sec_page_new" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="feature_sec_page_new_head">
                <h6>
                  Revolutionize Your Recruitment <br />
                  Experience with <span>AI-Powered Features</span>{" "}
                </h6>
              </div>
              <div className="feature_sec_page_new_body">
                {/* <div className='feature_sec_page_new_body_single'>
                                <div className='feature_sec_page_new_body_single_img'>
                                    <img src='../images/ai-img.svg' />
                                    <h6>AI Resume Builder</h6>
                                    <p>Build a standout resume effortlessly with our intuitive AI Resume Builder.</p>
                                </div>
                                <div className='feature_sec_page_new_body_single_cont'>
                                    <ul>
                                        <li>
                                            <h6>Smart Content Suggestions</h6>
                                            <p>Receive intelligent content suggestions to enhance your resume, ensuring it aligns with industry standards and job requirements.</p>
                                        </li>
                                        <li>
                                            <h6>Customizable Templates</h6>
                                            <p>Choose from a variety of professionally designed templates to create a visually appealing resume that reflects your personal brand.</p>
                                        </li>
                                        <li>
                                            <h6>Skills Optimization</h6>
                                            <p>Leverage AI to optimize the presentation of your skills, highlighting key strengths and increasing your visibility to recruiters.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                <div className="feature_sec_page_new_body_single midle_bg">
                  <div className="feature_sec_page_new_body_single_cont ai_interview">
                    <ul>
                      <li>
                        <h6>Tailored Questioning</h6>
                        <p>
                          Receive customized interview questions based on the
                          specific job requirements, allowing you to showcase
                          your skills and experiences effectively.
                        </p>
                      </li>
                      <li>
                        <h6>Technical Proficiency Evaluation</h6>
                        <p>
                          Showcase your technical proficiency through
                          AI-assisted questions designed to evaluate your
                          hands-on skills and problem-solving abilities.
                        </p>
                      </li>
                      <li>
                        <h6>Adaptive Interviewing</h6>
                        <p>
                          AI adapts the interview based on your responses,
                          ensuring a tailored experience that accurately
                          reflects your capabilities.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="feature_sec_page_new_body_single_img">
                    <img src={ai_interview} alt="" />
                    <h6>AI Interviews</h6>
                    <p>
                      Efficiently match candidates to opportunities with
                      AI-driven screening.
                    </p>
                  </div>
                </div>

                <div className="feature_sec_page_new_body_single btm_bg">
                  <div className="feature_sec_page_new_body_single_img">
                    <img src={ai_hiring} alt="" />
                    <h6>AI Hiring</h6>
                    <p>
                      Match candidates with the most relevant positions using AI
                      intelligence.
                    </p>
                  </div>
                  <div className="feature_sec_page_new_body_single_cont ai_hring">
                    <ul>
                      <li>
                        <h6>Automated Candidate Screening</h6>
                        <p>
                          Let AI handle the initial screening process, saving
                          time and ensuring a comprehensive assessment of
                          candidate qualifications.
                        </p>
                      </li>
                      <li>
                        <h6>Objective Decision Support</h6>
                        <p>
                          Remove bias from the decision-making process, as AI
                          evaluates candidates objectively, focusing on skills
                          and qualifications.
                        </p>
                      </li>
                      <li>
                        <h6>Efficient Shortlisting</h6>
                        <p>
                          AI shortlists candidates efficiently, presenting
                          recruiters with a curated list of individuals who best
                          match the job requirements.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features_transfrom_inner" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="features_transfrom_inner_sec_new_inner">
                <h6>
                  These features collectively transform the recruitment process,
                  making it more efficient, personalized, and effective for both
                  candidates and employers.
                </h6>
                {/* <div className='features_transfrom_btn'>
                                <button>Get Started</button>
                            </div> */}
                <div className="ai_enhave_btn_sec">{ctaBtn}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Future Hiring */}
      <section
        className="future_hiring_easy new_esay"
        style={{ display: "none" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="future_hiring_easy_main">
                <div className="future_hiring_easy_head future_hiring_easy_head1 text-center d-flex">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <h3>Embrace the future of Recruitment.</h3>
                  </div>
                  <div className="future_hiring_easy_right col-lg-8 col-md-12 col-sm-12 text-start">
                    <div className="future_hiring_easy-section">
                      <p className="text-left">
                        Choose <span>Haire</span> for a smarter, faster, and
                        more efficient recruitment experience. Join us in
                        revolutionizing the way you hire and find your dream
                        job.
                      </p>
                    </div>
                    <button className="explore_btn">Lets Explore</button>
                  </div>
                </div>
                <img src={border_corner} alt="" className="border-corner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Sections */}

      <section className="about_us_header">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="about_us_header_inner">
                        <h4 className="wow animate__bounceIn" data-wow-duration="1.3s">Streamlined Hiring with<br/>Intelligent Features for Modern Recruiters</h4>
                        <p className="wow animate__bounceIn" data-wow-duration="1.8s">Automate and optimize your recruitment process with advanced AI solutions. Enhance efficiency and make smarter hiring decisions effortlessly.</p>
                        <div className="scroll-down wow animate__bounceIn" data-wow-duration="2s">
                            <span class="scroll-dot"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>   

      <section className="ai_solution_haire">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ai_solution_haire_inner">
                <div className="ai_solution_haire_inner_head">
                  <h4>Haire AI Solution</h4>
                </div>
                <div className="ai_solution_haire_inner_body">
                  <div className="ai_solution_haire_inner_body_left">
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>1</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Tailored Questioning</h6>
                        <p>
                          Receive customized interview questions based on the
                          specific job requirements, allowing you to showcase
                          your skills and experiences effectively.
                        </p>
                      </div>
                    </div>
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>3</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Adaptive Interviewing</h6>
                        <p>
                          AI adapts the interview based on your responses,
                          ensuring a tailored experience that accurately
                          reflects your capabilities.
                        </p>
                      </div>
                    </div>
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>5</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Objective Decision Support</h6>
                        <p>
                          Remove bias from the decision-making process, as AI
                          evaluates candidates objectively, focusing on skills
                          and qualifications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ai_solution_haire_inner_body_left">
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>2</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Technical Proficiency Evaluation</h6>
                        <p>
                          Showcase your technical proficiency through
                          AI-assisted questions designed to evaluate your
                          hands-on skills and problem-solving abilities.
                        </p>
                      </div>
                    </div>
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>4</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Automated Candidate Screening</h6>
                        <p>
                          Let AI handle the initial screening process, saving
                          time and ensuring a comprehensive assessment of
                          candidate qualifications.
                        </p>
                      </div>
                    </div>
                    <div className="ai_solution_haire_inner_body_left_single">
                      <span>6</span>
                      <div className="ai_solution_haire_inner_body_left_single_cont">
                        <h6>Efficient Shortlisting</h6>
                        <p>
                          AI shortlists candidates efficiently, presenting
                          recruiters with a curated list of individuals who best
                          match the job requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="embacre_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="embacre_sec_new_inner">
                <div className="embacre_sec_new_inner_left">
                  <h5>
                    Embrace Future
                    <br />
                    Hiring with AI
                  </h5>
                </div>
                <div className="embacre_sec_new_inner_right">
                  <p>
                    Choose Haire for smarter, faster, and more efficient hiring.
                    Revolutionize your recruitment process with us.
                  </p>
                  <button
                    class="sign_up_btn_new after_mobile gradient-button"
                    onClick={handleDemoClick}
                  >
                    Book Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GetInTouch />

      <HomeFooterNew />
    </>
  );
};

export default Features;
