import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import "../Login/LoginEmailPass.css";

import { useNavigate } from "react-router-dom";
import { haire_gif_new, new_bg, OrIcon } from "../../assets/images/images";

const LoginV2 = () => {
  const navigate = useNavigate();

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="login_sec_new">
        <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>
              Experience Smart Recruitment in AI-Driven Recruitment Excellence.
            </p>
          </div>
          <div className="singin_sec_new_text">
            <div className="a">
              <h4 className="signin_qoutes">
                Step into the future of hiring with our AI tool that not only
                matches candidates to ideal positions but also initiates smart
                interviews.
              </h4>
            </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper wrapper">
          <div></div>
          <div>
            <div className="logo">
              <img
                className="haire-logo"
                src={haire_gif_new}
                onClick={() => navigate("/", { replace: true })}
                alt="Logo"
              />
            </div>

            <br />
            <br />

            <div className="singin_sec_new_right_inner">
              <GoogleInUp
                className="signup_google_sec"
                forContent="Log in with Google"
              />

              <br />

              <img src={OrIcon} alt="Logo" />

              <br />

              <div className="form-row text-button">
                <button
                  type="button"
                  className="forgot-passoword"
                  onClick={() => navigate("/login-with-verification-code")}
                >
                  Login with Verification Code
                </button>
              </div>
            </div>
          </div>
          <div>
            <p className="disclaimer">
              By Register with Haire.ai, you agree to our Terms of Use & Privacy
              Policy.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginV2;
