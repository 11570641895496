import { haire_logo } from "../../assets/images/images";
import jsPDF from "jspdf";
const pdf = new jsPDF();

const addWatermarkToCurrentPage = (pdf) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Save graphics state
  pdf.saveGraphicsState();

  // Calculate dimensions for the logo
  const logoWidth = 60; // Fixed width in mm
  const logoHeight = 20; // Fixed height in mm

  // Calculate center position
  const x = (pageWidth - logoWidth) / 2;
  const y = (pageHeight - logoHeight) / 2;

  // Set opacity
  pdf.setGState(new pdf.GState({ opacity: 0.2 }));

  // Add image
  pdf.addImage(
    haire_logo,
    "PNG",
    x,
    y,
    logoWidth,
    logoHeight,
    null,
    "NONE",
    20 // Rotation angle in degrees (jsPDF supports rotation in addImage)
  );

  // Restore graphics state
  pdf.restoreGraphicsState();
};

// Add watermark to each page after it's created
export const addWatermarkToAllPages = (pdf) => {
  const pageCount = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    pdf.setPage(i);
    addWatermarkToCurrentPage(pdf);
  }
};
