import React from 'react'
import TryBtn from '../NewSections/TryBtn'

const NewKeyFeatures = () => {
  return (
    <>
      <section className="key_features_sec new_sec_key">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="key_features_sec_inner">
                <div
                  className="key_features_sec_head wow animate__zoomIn"
                  data-wow-duration="4s"
                >
                  <h6>
                    Haire Key Features for a <span>Job Seeker</span>
                  </h6>
                </div>
                <div className="key_features_sec_inner_body">
                  <div
                    className="key_features_sec_inner_body_single wow animate__slideInLeft"
                    data-wow-duration="3s"
                  >
                    <h5>3-Step Profile Creation</h5>
                    <p>
                      Effortlessly complete your Haire.ai profile quickly and
                      efficiently in just minutes.
                    </p>
                    <span>1</span>
                  </div>
                  <div
                    className="key_features_sec_inner_body_single wow animate__slideInLeft"
                    data-wow-duration="3.3s"
                  >
                    <h5>Faster Job Matching</h5>
                    <p>
                      Quickly connects job seekers with suitable job opportunities
                      using advanced AI algorithms.
                    </p>
                    <span>2</span>
                  </div>
                  <div
                    className="key_features_sec_inner_body_single wow animate__slideInLeft"
                    data-wow-duration="3.6s"
                  >
                    <h5>Tailored Jobs</h5>
                    <p>
                      Delivers personalised job suggestions based on skills and
                      preferences.
                    </p>
                    <span>3</span>
                  </div>
                  <div
                    className="key_features_sec_inner_body_single wow animate__slideInLeft"
                    data-wow-duration="3.8s"
                  >
                    <h5>Application Tracking</h5>
                    <p>
                      Enables job seekers to track their application status and
                      progress in real-time.
                    </p>
                    <span>4</span>
                  </div>
                </div>
                <div
                  className="key_features_sec_btn_new wow animate__zoomIn"
                  data-wow-duration="4s"
                >
                  <TryBtn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewKeyFeatures
