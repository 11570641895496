import React from "react";
import { useNavigate } from "react-router-dom";

const TryBtn = ({ label }) => {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/try-haire");
  };
  return (
    <>
      <div className="inte_suu_ai_inner_body_btm_sec">
        <button onClick={handleBookDemoClick} class="try_button_new">
          {label ? label : "Try Haire"}
        </button>
        <p className="no_para">No Credit card needed.</p>
      </div>
    </>
  );
};

export default TryBtn;
