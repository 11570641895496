import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import "../Login/LoginEmailPass.css";

import { ThreeCircles } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { haire_gif_new, new_bg } from "../../assets/images/images";
import loginCodeStore from "../../zustand/loginCodeStore";
import LoginCodeModal from "./LoginCodeModal";
import DashboardHeader from "../../components/CommonComponents/DashboardHeader";
import OtpVerificationModal from "../SignIn/OtpVerificationModal";
import userStore from "../../zustand/userStore";
import { useSelector } from "react-redux";

//TILL

const LoginVerificationCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    formData,
    formErrors,
    setFormData,
    validateForm,
    loading,
    loginWithCode,
    validateField,
    openModal,
    closeModal,
  } = loginCodeStore();
  const {
    registerWithEmailPassword,
    loading: registrationLoading,
    openModal: registrationOpenModal,
  } = userStore();
  const [timer, setTimer] = useState();
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);

  const intervalRef = useRef(null); // Add this to track the interval

  const startTimer = () => {
    // Clear any existing interval first
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set the new interval
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  // Clean up the interval when component unmounts
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      loginWithCode(
        formData.email,
        () => {
          setTimer(60); // Reset the timer to 60 seconds
          openModal();
          startTimer();
        },
        redirectUrl ?? location.state?.redirectTo,
        () => {
          registerWithEmailPassword(
            formData.email,
            "defaultPassword123",
            () => {
              setTimer(60);
              // Reset the timer to 60 seconds
              registrationOpenModal();
              startTimer();
            }
          );
        }
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(name, value); // Update the state in the store
    validateField(name, value);
  };

  const handleBackButtonClick = () => {
    navigate("/login", { replace: true });
  };

  // Close modal on route change
  useEffect(() => {
    closeModal();
  }, [location, closeModal]);

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="login_sec_new">
        <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>
              Experience Smart Recruitment in AI-Driven Recruitment Excellence.
            </p>
          </div>
          <div className="singin_sec_new_text">
            <div className="a">
              <h4 className="signin_qoutes">
                Step into the future of hiring with our AI tool that not only
                matches candidates to ideal positions but also initiates smart
                interviews.
              </h4>
            </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper wrapper">
          <div></div>
          <div style={{ width: "100%" }}>
            <div className="logo">
              <img
                className="haire-logo"
                src={haire_gif_new}
                onClick={() => navigate("/", { replace: true })}
                alt="Logo"
              />
            </div>

            <br />

            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <label
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="label-wrapper">
                    Email ID <span className="required">*</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email id"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.email && (
                    <div className="error">{formErrors.email}</div>
                  )}
                </label>
              </div>
              <br />
              <div className="button-wrapper">
                <div className="login_btn_blue">
                  <button type="submit">
                    {loading || registrationLoading ? (
                      <ThreeCircles
                        visible={true}
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="loading-indicator"
                      />
                    ) : (
                      "Proceed"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="disclaimer-wrapper">
            <p className="disclaimer">
              By Register with Haire.ai, you agree to our Terms of Use & Privacy
              Policy.
            </p>
            <DashboardHeader
              backButtonLink={handleBackButtonClick}
              style={{ marginLeft: "-80px" }}
            />
          </div>
        </div>
        <LoginCodeModal
          timer={timer}
          setTimer={setTimer}
          startTimer={startTimer}
        />
        <OtpVerificationModal
          timer={timer}
          setTimer={setTimer}
          startTimer={startTimer}
        />
      </section>
    </>
  );
};

export default LoginVerificationCode;
