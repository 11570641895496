import React, { useEffect, useState } from "react";
import { download_icon, resumeicon } from "../../../../assets/images/images";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "../JobDetails/JobDetails.css";
import { ThreeCircles } from "react-loader-spinner";
import {
  calculateTimeSpan,
  formatDateTimeToLocalWithOffset,
} from "../../../../utils/utils";
import { assesmentPdf } from "../../../../utils/pdfFiles/assesmentPdf";

const Assesment = () => {
  const { id } = useParams();
  const jobsDetails = useSelector((state) => state.job?.details);
  const applicant = useSelector((state) => state?.job?.applicationResult);
  const assessmentResult = applicant?.assessment;

  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleDownloadAssessmentPdf = async (assessmentResult, applicant) => {
    if (assessmentResult && applicant) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await assesmentPdf(assessmentResult, applicant); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected assessment or applicant is undefined");
    }
  };

  return (
    <>
      <div className="Transcript_main">
        <div className="Transcript_main_pd">
          <div className="transcript_header">
            <div className="transcript_header_sub">
              <h5>Interview Assesment : {jobsDetails?.title} Position</h5>
              <h6>
                Duration:{" "}
                {calculateTimeSpan(
                  applicant?.interview_details?.started_at,
                  applicant?.interview_details?.ended_at
                )}{" "}
                min
              </h6>
            </div>
            <button
              className="transcript_download_button"
              onClick={() =>
                handleDownloadAssessmentPdf(assessmentResult, applicant)
              }
            >
              {loadingPdf ? ( // Show spinner if loadingPdf is true
                <ThreeCircles color="#1865c1" height={20} width={20} />
              ) : (
                <img src={download_icon} alt="" />
              )}
            </button>
          </div>
          <strong className="strong_pd">
            Timestamp:{" "}
            {applicant?.interview_details?.date &&
            applicant?.interview_details?.started_at
              ? formatDateTimeToLocalWithOffset(
                  applicant?.interview_details?.date,
                  applicant?.interview_details?.started_at
                )
              : "Timestamp unknown"}
          </strong>
        </div>
        <hr />
        {assessmentResult && (
          <div className="Transcript_main_action">
            {assessmentResult.map((item, index) => (
              <ul className="Transcript_page">
                <li className="trans_question">
                  <span>Problem Solving:</span>
                  <span className="rating_section">
                    {" "}
                    Rating : {item?.data?.problem_solving?.rating}
                    /10
                  </span>{" "}
                  <br />
                  {item?.data?.problem_solving?.description}
                </li>
                <li className="trans_question">
                  <span>Behavioral skills:</span>
                  <span className="rating_section">
                    {" "}
                    Rating : {item?.data?.behavioral_skills?.rating}/10
                  </span>{" "}
                  <br />
                  {item?.data?.behavioral_skills?.description}
                </li>
                <li className="trans_question">
                  <span>Communication Skills:</span>
                  <span className="rating_section">
                    {" "}
                    Rating : {item?.data?.communication_skills?.rating}/10
                  </span>{" "}
                  <br /> {item?.data?.communication_skills?.description}
                </li>
                <li className="trans_question">
                  <span>Technical Skills:</span>
                  <span className="rating_section">
                    {" "}
                    Rating : {item?.data?.technical_skills?.rating}/10
                  </span>{" "}
                  <br />
                  {item?.data?.technical_skills?.description}
                </li>
                <li className="trans_question">
                  <span>Overall Rating:</span>
                  <span className="rating_section">
                    {" "}
                    Rating : {item?.data?.overall_rating}/10
                  </span>{" "}
                  <br />
                  {item?.data?.overall_impression}
                </li>
              </ul>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Assesment;
