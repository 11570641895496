import React from 'react'
import { haire_img } from "../../../assets/images/images";
import { useNavigate } from 'react-router-dom';

const NewSkip = () => {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/try-haire");
  };
  return (
    <>
      <section className='skip_sec_new'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='skip_sec_new_onner'>
                <div className='skip_sec_new_onner_left wow animate__fadeInLeft' data-wow-duration="5s">
                  <h6>Skip the Waiting Lines—<br/>Try Haire!</h6>
                  <h5>Why wait when you can be first in line? Secure your meeting today and bypass the waiting queues.</h5>
                  <div class="inte_suu_ai_inner_body_btm_sec" style={{textAlign: 'center',width: '260px'}}>
                    <button onClick={handleBookDemoClick} class="try_button_new">Try Haire</button>
                    <p class="no_para">No Credit card needed.</p>
                  </div>
                </div>
                <div className='skip_sec_new_onner_right wow animate__fadeInRight' data-wow-duration="5s">
                    <img src={haire_img} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default NewSkip
