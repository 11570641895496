import { CountdownCircleTimer } from "react-countdown-circle-timer";

const ResultsCountdown = ({ renderTime, onUpdate, onComplete }) => {
  return (
    <section className="get_ready_panel_sec">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="get_ready_panel_sec_inner">
              <div className="get_ready_panel_sec_inner_box">
                <div className="timer-wrapper">
                  <CountdownCircleTimer
                    isPlaying
                    duration={3}
                    colors={["#fff", "#fff", "#fff", "#fff"]}
                    colorsTime={[10, 6, 3, 0]}
                    onUpdate={onUpdate}
                    onComplete={onComplete}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsCountdown;
