import React, { useCallback, useEffect, useRef, useState } from "react";
import MyJobsTable from "./MyJobsTable";
import "./MyJobs.css";
import ClosedJobs from "./ClosedJobs";
import JobsDraft from "./JobsDraft";
import { useLocation, useNavigate } from "react-router";
import { Search_icon } from "../../../../assets/images/images";
import { debounce } from "lodash";
import { setJobPageFilters } from "../../../../redux/reducers/jobReducer";
import { useDispatch } from "react-redux";
const MyJobs = ({ creditsButtonRef }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const query = useQuery();
  const [activeTab, setActiveTab] = useState("myJobs");
  const [status, setStatus] = useState(null);
  const inputRef = useRef(null);

  const tabStatusMap = {
    myJobs: null,
    draftJobs: 1,
    closedJobs: 3,
  };

  const tabStyle = {
    borderRadius: "20px",
    border: "2px dashed #blue",
    padding: "8px 20px",
    cursor: "pointer",
    marginRight: "10px",
    width: "200px",
    fontSize: "14px",
    fontWeight: "700",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#DFEEFF",
    color: "#1865C1",
    border: "none",
  };

  // const mobileMediaQuery = "@media only screen and (max-width: 767px)";

  // const mobileTabStyle = {
  //   ...tabStyle,
  //   width: "auto",
  //   fontSize: "10px",
  // };
  // const responsiveTabStyle = {
  //   ...tabStyle,
  //   [mobileMediaQuery]: mobileTabStyle,
  // };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentTab = pathSegments[pathSegments.length - 1];
    if (currentTab && Object.keys(tabStatusMap).includes(currentTab)) {
      setActiveTab(currentTab);
      setStatus(tabStatusMap[currentTab]);
    }
  }, [location]);

  const handleTabChange = (tabName) => {
    dispatch(setJobPageFilters({ current: 1 }));
    setActiveTab(tabName);
    setStatus(tabStatusMap[tabName]);
    navigate(`/dashboard/my-jobs/${tabName}`);
  };
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
    // Focus the input without scrolling
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, []);

  return (
    <>
      <p className="header_font">My Jobs</p>
      <div className="row">
        <div className="col-6 col-sm-10 menulist new_menu_sec_jobs">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="text-center"
              style={activeTab === "myJobs" ? activeTabStyle : tabStyle}
              onClick={() => handleTabChange("myJobs")}
            >
              My Jobs
            </div>
            <div
              className="text-center"
              style={activeTab === "draftJobs" ? activeTabStyle : tabStyle}
              onClick={() => handleTabChange("draftJobs")}
            >
              Draft
            </div>
            <div
              className="text-center"
              style={activeTab === "closedJobs" ? activeTabStyle : tabStyle}
              onClick={() => handleTabChange("closedJobs")}
            >
              Closed
            </div>
          </div>
        </div>
      </div>

      {activeTab === "myJobs" && (
        <MyJobsTable activeTab={activeTab} status={status} creditsButtonRef={creditsButtonRef} />
      )}
      {activeTab === "closedJobs" && (
        <ClosedJobs activeTab={activeTab} status={status} />
      )}
      {activeTab === "draftJobs" && (
        <JobsDraft activeTab={activeTab} status={status} />
      )}
    </>
  );
};

export default MyJobs;

export const Searchbar = ({ onSearchSubmit, inputValue, setInputValue }) => {
  const [localInputValue, setLocalInputValue] = useState(inputValue);
  const inputRef = useRef(null);

  // Debounced version of the setInputValue function
  const debouncedSetInputValue = useCallback(
    debounce((value) => {
      setInputValue(value);
    }, 300),
    [] // Ensure the debounce function is not recreated on every render
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      debouncedSetInputValue.flush(); // Immediately invoke debounced function
      if (inputRef.current) {
        inputRef.current.blur(); // Optionally remove focus after submitting
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setLocalInputValue(value); // Update local input value immediately
    debouncedSetInputValue(value); // Debounce the main input value update
  };

  useEffect(() => {
    setLocalInputValue(inputValue)
  }, [inputValue])

  return (
    <div className="Search_job">
      <img
        src={Search_icon}
        alt="Search_icon"
        className="Search_icon"
        style={{ cursor: 'pointer' }}
      />
      <input
        ref={inputRef}
        type="text"
        placeholder="Search"
        value={localInputValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
};

export const Reset = ({ onReset }) => {
  return (
    <div className="reset_container">
      <button className="reset_btn" onClick={onReset}>
        Reset
      </button>
    </div>
  );
};

export const StatusFilter = ({ onStatusChange, selectedStatus }) => {
  const handleStatusChange = (e) => {
    const value = e.target.value;
    onStatusChange(value);
  };
  return (
    <>
      <div className="emp_filter">
        <span className="">Status:</span>
        <select
          className="selectedStatus jobtype"
          onChange={handleStatusChange}
          value={selectedStatus}
        >
          <option className="selected_section" value="All">
            All
          </option>
          <option value="2">Active</option>
          <option value="3">Closed</option>
          <option value="4">Inactive</option>
        </select>
      </div>
    </>
  );
};

export const StatusFilterApplicant = ({ onStatusChange, selectedStatus }) => {
  const handleStatusChange = (e) => {
    const value = e.target.value;
    onStatusChange(value);
  };
  return (
    <>
      <div className="responsive_status">
        <span className="custom-margin">Status:</span>
        <select
          className="selectedStatus jobtype"
          onChange={handleStatusChange}
          value={selectedStatus}
        >
          <option className="selected_section" value="All">
            All
          </option>
          <option value="Shortlisted">Shortlisted</option>
          <option value="Rejected">Rejected</option>
          <option value="Under-Review">Under Review</option>
          <option value="Fit-For-Job">Applied</option>
        </select>
      </div>
    </>
  );
};

export const SortFilter = ({ onSortChange, selectedValue, activeTab }) => {
  return (
    <>

      <div className="emp_filter">
        <div className="sort_p">Sort by:</div>
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onSortChange(e.target.value)}
          value={selectedValue}
        >
          <option value="">All</option>
          {activeTab === "myJobs" ? (
            <>
              <option value="published_date">Newest Job</option>
              <option value="-published_date">Oldest Job</option>
            </>
          ) : (
            <>
              <option value="created_at">Newest Job</option>
              <option value="-created_at">Oldest Job</option>
            </>
          )}
          <option value="title">A-Z</option>
          <option value="-title">Z-A</option>
        </select>
      </div>
    </>
  );
};

export const SortFilterApplicant = ({ onSortChange, selectedValue }) => {
  return (
    <>
      <div className="sort_p">Sort by:</div>
      <div className="responsive_status">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onSortChange(e.target.value)}
          value={selectedValue}
        >
          <option value="">All</option>
          <option value="created_at">Newest Job</option>
          <option value="-created_at">Oldest Job</option>
          <option value="a-z">A-Z</option>
          <option value="-z-a">Z-A</option>
        </select>
      </div>
    </>
  );
};

export const EmploymentFilter = ({ onTypeChange, selectedType }) => {
  return (
    <>
      <div className="emp_filter">
        <span className="type_filter">Employement Type:</span>
        <select
          className="selectedStatus jobtype"
          onChange={(e) => onTypeChange(e.target.value)}
          value={selectedType}
        >
          <option value="All">All</option>
          <option value="1">Full Time</option>
          <option value="2">Part Time</option>
          <option value="3">Remote</option>
          <option value="4">Temporary</option>
          <option value="5">Contract</option>
          <option value="6">Freelancer</option>
          <option value="7">Internship</option>
        </select>
      </div>
    </>
  );
};
