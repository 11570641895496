import React from "react";
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";
import { req_14, req_13, req_12, req_11,haire_gif_new } from "../../../assets/images/images";
import { Link } from "react-router-dom";
const HowWorks = () => {
  const handleLinkClick = () => {
    localStorage.setItem("userType", "recruiter");
  };
  return (
    <>
      <section className="how_works_for_req new_how_work">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="how_works_for_req_inner">
                <div
                  className="how_works_for_req_inner_head wow animate__zoomIn"
                  data-wow-duration="3s"
                >
                  <h3>How it works</h3>
                </div>
                <div className="how_works_for_req_inner_body_sec">
                  <div
                    className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                    data-wow-duration="3s"
                  >
                    <div className="how_works_for_req_inner_body_sec_single_img">
                      <img src={req_11} alt="Sign Up as a Recruiter" />
                      <span className="req_vid">
                        <img src={haire_gif_new}/>
                      </span>
                    </div>
                  </div>
                  <div
                    className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                    data-wow-duration="3s"
                  >
                    <div className="how_works_for_req_inner_body_sec_single_img">
                      <img src={req_12} />
                    </div>
                  </div>
                  <div
                    className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                    data-wow-duration="3s"
                  >
                    <div className="how_works_for_req_inner_body_sec_single_img">
                      <img src={req_13} />
                    </div>
                  </div>
                  <div
                    className="how_works_for_req_inner_body_sec_single wow animate__slideInLeft"
                    data-wow-duration="3s"
                  >
                    <div className="how_works_for_req_inner_body_sec_single_img">
                      <img src={req_14} />
                    </div>
                  </div>
                </div>
                <div
                  className="sign_up_req_btn_sec wow animate__zoomIn flex_direction_column"
                  data-wow-duration="3s"
                >
                  <Link
                    to={{
                      pathname: "/try-haire",
                      state: { userType: "recruiter" },
                    }}
                    onClick={handleLinkClick}
                  >
                    Try Haire
                  </Link>
                  <p className="wht_para">No Credit card needed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowWorks;
