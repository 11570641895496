import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import "../Login/LoginEmailPass.css";

import { ThreeCircles } from "react-loader-spinner";
import { haire_gif_new, new_bg } from "../../assets/images/images";
import PasswordField from "../../components/CommonComponents/PasswordField";
import loginStore from "../../zustand/loginStore";
import { useLocation, useNavigate } from "react-router-dom";
import LoginOtpVerificationModal from "./LoginOtpVerificationModal";

const LoginEmailPass = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    formData,
    formErrors,
    setFormData,
    validateForm,
    loading,
    loginWithEmailPassword,
    validateField,
    openModal,
  } = loginStore();

  const [showPassword, setShowPassword] = useState(false);
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);
  const [timer, setTimer] = useState();

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval); // Stop the interval when the timer reaches 0
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval); // Clean up the interval on component unmount
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      loginWithEmailPassword(
        formData.email,
        formData.password,
        dispatch,
        navigate,
        () => {
          setTimer(60); // Reset the timer to 60 seconds
          openModal();
          startTimer();
        },
        redirectUrl ?? location.state?.redirectTo
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(name, value); // Update the state in the store
    validateField(name, value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="login_sec_new">
        <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>
              Experience Smart Recruitment in AI-Driven Recruitment Excellence.
            </p>
          </div>
          <div className="singin_sec_new_text">
            <div className="a">
              <h4 className="signin_qoutes">
                Step into the future of hiring with our AI tool that not only
                matches candidates to ideal positions but also initiates smart
                interviews.
              </h4>
            </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper">
          <div className="logo">
            <img
              className="haire-logo"
              src={haire_gif_new}
              onClick={() => navigate("/", { replace: true })}
              alt="Logo"
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label
                style={{
                  width: "100%",
                }}
              >
                <div className="label-wrapper">
                  Email ID <span className="required">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email id"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formErrors.email && (
                  <div className="error">{formErrors.email}</div>
                )}
              </label>
            </div>
            <div className="form-row">
              <label
                style={{
                  width: "100%",
                }}
              >
                <div className="label-wrapper">
                  Password <span className="required">*</span>
                </div>
                <PasswordField
                  name={"password"}
                  value={formData.password}
                  placeholder={"Enter your password"}
                  showPassword={showPassword}
                  togglePasswordVisibility={togglePasswordVisibility}
                  onChange={handleChange}
                />
                {formErrors.password && (
                  <div className="error">{formErrors.password}</div>
                )}
              </label>
            </div>
            <div className="form-row text-button">
              <button
                type="button"
                className="forgot-passoword"
                onClick={() => navigate("/login-with-verification-code")}
              >
                Login with Verification Code
              </button>
              <button
                type="button"
                className="forgot-passoword"
                onClick={() => navigate("/forget-password")}
              >
                Forgot Password?
              </button>
            </div>
            <div className="button-wrapper">
              <div className="login_btn_blue">
                <button type="submit">
                  {loading ? (
                    <ThreeCircles
                      visible={true}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
            </div>
          </form>
          {/* <span className="or_text">Or</span> */}

          <div className="singin_sec_new_right_inner">
            {/* <div
              className="signup_google_sec"
              onClick={() => navigate("/login-with-phone")}
            >
              Log In with Phone Number
            </div> */}
            <span className="or_text">Or</span>
            <GoogleInUp
              className="signup_google_sec"
              forContent="Log in with Google"
            />
            {/* <AppleInUp
              forContent="Log in with Apple"
              className="signup_google_sec"
              disabled={phone !== null}
            /> */}
            <hr className="spacer20px" />
            <span className="alredy_have_account">
              Don’t have an account?{" "}
              <a className="login_btn_alredy" href="/sign-up">
                Sign Up
              </a>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginEmailPass;
