import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { haire_gif_new, new_bg } from "../../assets/images/images";
import GoogleInUp from "../../components/CommonComponents/GoogleInUp";
import "../SignIn/SignUpPage.css";

import { MuiTelInput } from "mui-tel-input";
import { ThreeCircles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { ReactComponent as RecuriterIconBlue } from "../../assets/images/blue_bag.svg";
import { ReactComponent as JobSeekerIconBlue } from "../../assets/images/blue_glass.svg";
import { ReactComponent as JobSeekerIcon } from "../../assets/images/jobseeker-icon.svg";
import { ReactComponent as RecuriterIcon } from "../../assets/images/recruiter-icon.svg";
import PasswordField from "../../components/CommonComponents/PasswordField";
import { getCountryCode } from "../../utils/utils";
import registrationStore from "../../zustand/registrationStore";
import userStore from "../../zustand/userStore";
import OtpVerificationModal from "./OtpVerificationModal";

const SignUpPage = () => {
  const navigate = useNavigate();

  const { formData, formErrors, setFormData, validateForm, validateField } =
    registrationStore();
  const { registerWithEmailPassword, loading, openModal } = userStore();
  const [timer, setTimer] = useState();
  const [selectedUserType, setSelectedUserType] = useState("jobseeker");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useSelector((state) => state?.location?.userCountry);

  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setSelectedUserType(storedUserType);
      setFormData("userType", storedUserType);
    }

    return () => {
      localStorage.removeItem("userType");
    };
  }, [setFormData]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData(name, value); // Update the state in the store\
    validateField(name, value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval); // Stop the interval when the timer reaches 0
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval); // Clean up the interval on component unmount
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      registerWithEmailPassword(formData.email, formData.password, () => {
        setTimer(60);
        // Reset the timer to 60 seconds
        openModal();
        startTimer();
      });
    }
  };

  const handleUserTypeSelection = (userType) => {
    setSelectedUserType(userType);
    setFormData("userType", userType); // Update the formData to include the user type
  };

  useEffect(() => {
    // Assuming openModal is something that could be triggered from multiple places,
    // ensure the timer starts only if it's supposed to.
    const intervalId =
      timer > 0 &&
      setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timer]);

  return (
    <>
      <section className="singin_sec_new">
        <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>
              Experience Smart Recruitment in AI-Driven Recruitment Excellence.
            </p>
          </div>
          <div className="singin_sec_new_text">
            {selectedUserType === "recruiter" ? (
              <div className="a">
                <h4 className="signin_qoutes">
                  Step into the future of hiring with our AI tool that not only
                  matches candidates to ideal positions but also initiates smart
                  interviews.
                </h4>
              </div>
            ) : (
              <div className="b">
                <h4 className="signin_qoutes">
                  Get ready for interviews that matter. Our AI-driven interview
                  process is tailored to highlight your unique capabilities,
                  connecting you directly with opportunities where you can
                  thrive.
                </h4>
              </div>
            )}
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper">
          <div className="logo">
            <img
              src={haire_gif_new}
              className="haire-logo"
              alt="Logo"
              onClick={() => navigate("/", { replace: true })}
            />
          </div>

          <div className="toggle-buttons">
            <div
              className={`toggle-btn ${
                selectedUserType === "recruiter" ? "active" : ""
              }`}
              onClick={() => handleUserTypeSelection("recruiter")}
            >
              {/* <img src={recruiter_icon} alt="" /> */}
              {selectedUserType === "recruiter" ? (
                <RecuriterIconBlue className="icon-s" />
              ) : (
                <RecuriterIcon className="icon-s" />
              )}
              Recruiter
            </div>
            <div
              className={`toggle-btn ${
                selectedUserType === "jobseeker" ? "active" : ""
              }`}
              onClick={() => handleUserTypeSelection("jobseeker")}
            >
              {/* <img src={jobseeker_icon} alt="" /> */}
              {selectedUserType === "jobseeker" ? (
                <JobSeekerIconBlue className="icon-s" />
              ) : (
                <JobSeekerIcon className="icon-s" />
              )}
              Job Seeker
            </div>
            {formErrors.userType && (
              <div className="error">{formErrors.userType}</div>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label
                style={{
                  width: selectedUserType === "jobseeker" ? "100%" : "48%",
                }}
                className="res-wd"
              >
                <div className="label-wrapper">
                  Full Name <span className="required">*</span>
                </div>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Enter full name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  className="res-wd"
                  disabled={loading}
                />
                {formErrors.fullName && (
                  <div className="error">{formErrors.fullName}</div>
                )}
              </label>
              {selectedUserType === "recruiter" && (
                <label className="res-wd">
                  <div className="label-wrapper">
                    Company Name <span className="required">*</span>
                  </div>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Enter company name"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                    disabled={loading}
                  />
                  {formErrors.companyName && (
                    <div className="error">{formErrors.companyName}</div>
                  )}
                </label>
              )}
            </div>
            <div className="form-row">
              <label className="res-wd">
                <div className="label-wrapper">
                  Email ID <span className="required">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email id"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
                {formErrors.email && (
                  <div className="error">{formErrors.email}</div>
                )}
              </label>
              <label className="res-wd">
                <div className="label-wrapper">
                  Contact Number <span className="required">*</span>
                </div>

                <div className="inner_input_box_select">
                  <MuiTelInput
                    name="contact"
                    variant="filled"
                    defaultCountry={location?.toUpperCase()}
                    preferredCountries={[location?.toUpperCase()]}
                    value={formData.contact}
                    onChange={(value) => {
                      // Remove any whitespaces in between the phone number
                      const formattedValue = value?.replace(/\s+/g, "");
                      handleChange({
                        target: {
                          name: "contact", // Update contact
                          value: formattedValue,
                        },
                      });
                      handleChange({
                        target: {
                          name: "countryCode", // Update countryCode
                          value: getCountryCode(value),
                        },
                      });
                    }}
                    className="custom-phone-input"
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      // startAdornment: <></>,
                    }}
                    disabled={loading}
                    sx={{
                      height: 42, // Set height of the TextField
                      "& .MuiInputBase-input": {
                        height: "30px", // Adjust the height of the input to align with the 42px of the TextField
                        padding: "6px 14px", // Adjust padding to vertically center the text
                      },
                      "& .MuiFilledInput-root": {
                        borderRadius: "4px", // Optional: Adjust border radius if needed
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "inherit",
                        background: "rgba(248, 248, 248, 1)",
                      },
                    }}
                  />
                </div>
                {formErrors.contact && (
                  <div className="error">{formErrors.contact}</div>
                )}
              </label>
            </div>
            <div className="form-row">
              <label className="res-wd">
                <div className="label-wrapper">
                  Password <span className="required">*</span>
                </div>
                <PasswordField
                  name={"password"}
                  value={formData.password}
                  placeholder={"Enter your password"}
                  showPassword={showPassword}
                  togglePasswordVisibility={togglePasswordVisibility}
                  onChange={handleChange}
                  disabled={loading}
                />
                {formErrors.password && (
                  <div className="error">{formErrors.password}</div>
                )}
              </label>
              <label className="res-wd">
                <div className="label-wrapper">
                  Confirm Password <span className="required">*</span>
                </div>
                <PasswordField
                  name={"confirmPassword"}
                  value={formData.confirmPassword}
                  placeholder={"Confirm your password"}
                  showPassword={showConfirmPassword}
                  togglePasswordVisibility={toggleConfirmPasswordVisibility}
                  onChange={handleChange}
                  disabled={loading}
                />
                {formErrors.confirmPassword && (
                  <div className="error">{formErrors.confirmPassword}</div>
                )}
              </label>
            </div>
            <div className="button-wrapper">
              <div className="login_btn_blue">
                <button type="submit" className="register-comon-btn">
                  {loading ? (
                    <ThreeCircles
                      visible={true}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </div>
          </form>
          <div className="singin_sec_new_right_inner">
            <GoogleInUp
              className="signup_google_sec"
              forContent="Sign up with Google"
            />
            {/* <hr className="spacer20px" /> */}
            {/* <AppleInUp
                  forContent="Sign up with Apple"
                  className="signup_google_sec"
                /> */}
            {/* <hr className="spacer30px" /> */}
            <span className="alredy_have_account">
              Already have an account?{" "}
              <Link to="/login" className="login_btn_alredy">
                Log in
              </Link>
            </span>
          </div>
        </div>
        <OtpVerificationModal
          timer={timer}
          setTimer={setTimer}
          startTimer={startTimer}
        />
      </section>
    </>
  );
};

export default SignUpPage;
