import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { pause_vid, play_vid, Thumbnail } from "../../../assets/images/images";
import "../../Home/home.css";
import "./vedio.css";

// Lazy load the video component
const VideoPlayer = lazy(() =>
  Promise.resolve({
    default: ({ videoRef, poster, source }) => (
      <video
        ref={videoRef}
        className="video-player wow animate__zoomIn mb-3"
        loop
        poster={poster}
        preload="none"
        data-wow-duration="3s"
      >
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ),
  })
);

const HaireVideo = ({ hideCta }) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  useEffect(() => {
    // Intersection Observer to load video only when in viewport
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVideoVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const container = document.querySelector(".video_sec_home_new");
    if (container) {
      observer.observe(container);
    }

    return () => observer.disconnect();
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    localStorage.setItem("userType", "recruiter");
    navigate("/sign-up");
  };

  return (
    <section className="video_sec_home_new">
      <div className="container-fluid">
        <div className="col-lg-12 col-md-12 d-flex justify-content-center">
          <div
            className={`haire-landing-video position-relative ${
              isPlaying ? "playing" : ""
            }`}
          >
            <Suspense
              fallback={
                <img
                  src={Thumbnail}
                  alt="Video thumbnail"
                  className="video-placeholder mb-3"
                  loading="lazy"
                />
              }
            >
              {isVideoVisible && (
                <VideoPlayer
                  videoRef={videoRef}
                  poster={Thumbnail}
                  source="https://haire.s3.ap-south-1.amazonaws.com/Haire_vid.mp4"
                />
              )}
            </Suspense>

            {!isPlaying && (
              <button
                className="center-button play-button"
                onClick={handlePlay}
                aria-label="Play video"
              >
                <img src={play_vid} alt="" loading="lazy" />
              </button>
            )}
            {isPlaying && (
              <button
                className="center-button pause-button"
                onClick={handlePause}
                aria-label="Pause video"
              >
                <img src={pause_vid} aalt="Pause button" loading="lazy" />
              </button>
            )}
            {hideCta && (
              <div
                className="ai_enhave_btn_sec wow animate__zoomIn"
                data-wow-duration="3s"
              >
                <button className="try-button Ready-btn" onClick={handleClick}>
                  Ready to hire?
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaireVideo;
